//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

//
//  Common
//  _____________________________________________
body .tig-postnl-field-group.default.admin__control-fields {
    width: 74%;
    text-align: right;
    float: right;
        margin-top: 9px;
        @media(max-width: 1024px) {
        width: 100%;
        }
}
body .tig-postnl-field-group .field {
	    text-align: left;
	    @media(max-width: 767px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 14px !important;
	    }
}
body .tig-postnl-field-group.default.admin__control-fields label.label {
	    text-align: left;
    font-weight: 700;
    @media(max-width: 992px) {
    	font-size: 12px;
    }
        @media(max-width: 992px) {
    	font-size: 12px;
    }
}
body .tig-postnl-field-group .field._required>.label:after{
	    position: absolute;
}
body.checkout-index-index .field.street > .control .field[name="shippingAddress.street.0"] {
	width: 100%;
	    padding-top: 0;
}
body.checkout-index-index .field.street > .control .field[name="shippingAddress.street.0"] .label {
    width: 26%;
            @media(max-width: 767px) {
        width: 100%;
        }
}
body.checkout-index-index .field.street > .control .field[name="shippingAddress.street.0"] .control {
	    width: 74%;
	            @media(max-width: 767px) {
        width: 100%;
        }
}
.tig-postnl-validation-message{position:static; padding-left: 26%; display:block; float:left;@media(max-width:1024px){padding-left:0;}}
.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 auto $checkout-wrapper__margin;
    max-width: 1200px;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }
}

//
//  PostNL API address field response css
//  _____________________________________________
.checkout-index-index {
    .field.address-autofill-formatted-output.admin__scope-old {
        padding-left: 25.8%;
        @media(max-width: 767px) {
            padding-left: 0;
        }
        address {
            background-color: #f4f4f4;
            padding: 20px;
            box-sizing: border-box;
        }
    }
}
//
//  address and postcode field changes
//  _____________________________________________
.field.address-autofill-formatted-output.admin__scope-old + fieldset.field.street.admin__control-fields.required > .control {
    display: flex;
    flex-wrap: wrap;
    padding-left: 26%;
    box-sizing: border-box;
    margin: 0 -5px;
	@media(max-width: 767px) {
    	width: 100%;
    	padding: 0;
    	margin: 0;
    }
    div[name*="shippingAddress.street.1"] {
	    order: 2;
	    width: 33.33%;
	    margin: 0;
	    margin-top: 10px;
	    padding: 0 5px;
	    box-sizing: border-box;
	    @media(max-width: 767px) {
	    	width: 100%;
	    	padding: 0;
	    }
	    label {
	        line-height: 11px;
	    }
	}
	div[name*="shippingAddress.street.2"] {
	    order: 3;
	    width: 33.33%;
	    margin: 0;
	    margin-top: 10px;
	    padding: 0 5px;
	    box-sizing: border-box;
	   	@media(max-width: 767px) {
	    	width: 100%;
	    	padding: 0;
	    }
	    label {
	        line-height: 11px;
	    }
	}
	div[name*="shippingAddress.postcode"] {
		order: 1;
	    width: 33.33%;
	    margin-top: 10px;
	    padding: 0 5px;
	    box-sizing: border-box;
	   	@media(max-width: 767px) {
	    	width: 100%;
	    	padding: 0;
	    }
	}
}
