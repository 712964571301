.header-content-right {
  display: table-cell;
  vertical-align: top;
  white-space: nowrap;
  width: 1%;
}
.header-left-links {
  list-style-type: none;
  border-bottom: 2px solid #393939;
  padding: 0 12px 10px;
  margin: -4px 0 0;

  li {
    display: inline-block;
    margin: 0 50px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      color: #fff;
    }
  }
}
.header-top-right-text {
  text-align: right;
  font-size: 13px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: -2px;

  .icon-shape-17-check-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    background-color: #6dd49b;
    color: #1d7042;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    margin-right: 14px;
  }
  > * {
    vertical-align: middle;
  }
}
.header-navigation-wrapper {
  background-color: #151515;
}
.minicart-wrapper {
 .action.showcart {
  &:hover {
    .icon-hamburger {
      color: #fff;
    }
  }
  }

  .action.viewcart {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      border: 2px solid rgba(21, 21, 21, 0.1);
      padding: 0 15px;
      color: #151515;
      font-size: 16px;
      font-weight: 600;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
            background-color: #f33e26;
            color: #fff;
            text-decoration: none;
      }
  }
}
.rewardpoints-cart-sidebar {
    .rewardpoints-name-img {
        margin-right: 7px;
    }
}
.search-autocomplete {
  display: none !important;
}
.searchautocomplete__autocomplete {
  position: absolute;
  background-color: #fff;
  border: 4px solid #f6f6f6;
  BOX-SIZING: border-box;
  color: #232323;
  display: none;
  z-index: 1;

  .block-search & {
    display: block;
  }
  .show-all-results {
    text-align: center;
    background-color: #f6f6f6;
    padding: 5px 15px 1px;

    a {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      border: 2px solid rgba(21, 21, 21, 0.1);
      padding: 0 15px;
      color: #151515;
      font-size: 16px;
      font-weight: 600;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      margin: 5px 0;

      &:hover {
            background-color: #f33e26;
            color: #fff;
            text-decoration: none;
      }
    }
  }
  .searchautocomplete__index-popular {
    padding: 10px 15px;
  }
  .empty-result {
    padding: 12px 15px;
  }
}
.searchautocomplete__index-magento_search_query,
.searchautocomplete__index-magento_catalog_product {

  ul {
    list-style-type: none;
    margin: 0;
    padding: 12px 15px;

    li {
      > img {
        float: left;
        max-width: 50px;
        max-height: 60px;
        margin-right: 15px;
      }
      .title {
        color: #232323;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .index-title {
    background-color: #f6f6f6;
    text-align: right;
    padding: 5px 15px;
  }
}
.searchautocomplete__index-magento_search_query {
  .index-title {
    text-align: left;
  }
}
a.show-search-button {
  display: none;
  position: absolute;
  width: 80px;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  background-color: #353535;
  left: 80px;
  bottom: 0;
  color: #fff;
  text-align: center;
  font-size: 25px;
  text-decoration: none;
  z-index: 1;

  &:before {
    content: '\e90c';
    font-family: 'icomoon';
  }
}
.searchautocomplete__item-magento_catalog_product {
  clear: both;
  display: table;
  margin-bottom: 15px;
}
.checkout-cart-index, .contact-index-index, .multishipping-checkout-register, .customer-account-login, .account,
.customer-account-logoutsuccess {
    .page-header {
        margin-bottom: 20px;

        @media only screen and (max-width: 860px) {
            margin-bottom: 40px;
        }
    }
}
#search {
      &::-webkit-input-placeholder {color: #909090 !important;}
      &::-moz-placeholder {color: sh#909090 !important;}
      &:-moz-placeholder {color: #909090 !important;}
      &:-ms-input-placeholder {color: #909090 !important;}
}

@media only screen and (max-width: 1400px) {
  .minicart-wrapper {
    margin-right: -15px;

    .action.showcart {
      &:before {
        color: #b7301e;
        font-size: 27px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 27px;
        right: 15px;
        line-height: 1;
      }
      .counter.qty {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        margin-right: -16px;
      }
      .cart-subtotal-wrapper{
        .cart-subtotal {
          display: none;
        }
      }
      .icon-hamburger {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 1120px) {
  .header.content {
    flex-direction: column;
    display: flex;
    padding: 0;
    background-color: #151515;
  }
  .header-content-right {
    order: 1;
    display: block;
    width: 100%;
    border-bottom: 1px solid #393939;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 16px;
    background-color: #232323;

    .header-left-links {
      float: right;
      border-bottom: none;
      padding: 0;
      margin-right: 27px;
      margin-top: 2px;
    }
    .header-top-right-text {
      float: left;
      margin-left: 27px;
    }
    .header-top-right-text {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 0;

      .icon-shape-17-check-icon {
        width: 26px;
        height: 26px;
        line-height: 26px;
      }
    }
  }
  .logo {
    display: block;
    order: 2;
    margin: 18px auto 13px;
    max-width: 262px;
  }
  .block-search {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    padding-left: 0;
  }
  .header-left-links li {
    margin-right: 28px;
  }
  .minicart-wrapper .action.showcart {
    &:before {
      right: 25px;
    }
    .cart-subtotal-wrapper {
      width: 80px;
      height: 80px;
      line-height: 80px;
      padding: 0;
    }
  }
  .header-navigation-container {
    padding: 0;
  }
  .minicart-wrapper {
    margin-right: 0;
  }
  .header-navigation-wrapper {
    margin-top: -80px;
    position: relative;
  }
  .nav-toggle {
    display: block;
  }
  a.show-search-button {
    display: block;
  }
}

@media only screen and (max-width: 860px) {
  .header-content-right {
    padding: 0;
    height: 0;
    border: none;

    .header-left-links {
      display: none;
    }
    .header-top-right-text {
      position: absolute;
      top: 100%;
      z-index: 2;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(21, 21, 21, 0.8);
      margin: 0;
      height: 30px;
      line-height: 30px;
      font-size: 12px;

      .icon-shape-17-check-icon {
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  a.show-search-button {
    display: none;
  }
  .block-search {
    display: block !important;
    position: static;

    .control {
      padding: 10px 20px;
    }
    input {
      height: 44px;
      padding-left: 20px;
      -webkit-background-size: 12px;
      background-size: 12px;
    }
    .action.search {
      height: 44px;
      line-height: 44px;
      top: 10px;
      right: 20px;
    }
  }
  .nav-toggle {
    width: 50px;
    height: 50px;
    line-height: 50px;

    &:before {
      font-size: 14px;
    }
    &.active {
      &:before, &:after {
        width: 20px;
      }
    }
  }
  .logo {
    max-width: 160px;
    margin-top: 11px;
    margin-bottom: 9px;
  }
  .minicart-wrapper .action.showcart {
    &:before {
      right: 12px;
      height: 18px;
      font-size: 18px;
    }
    .counter.qty {
      width: 27px;
      height: 27px;
      line-height: 27px;
    }
    .cart-subtotal-wrapper {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
  .header-navigation-wrapper {
    margin-top: -50px;
  }
  .header.content {
    height: 50px;

    .block-search {
      display: none !important;
    }
  }
  .block-search .label {
    display: none;
  }
}

.search-in3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
  padding: 0 50px 0 28px;
}