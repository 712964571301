.homepage-slider {
  color: #313131;
  min-height: 745px;

  .container {
    max-width: 1330px;
  }
  .slide-content {
    position: relative;
  }
  .item {
    height: 745px;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 80px;

    &.slide-1 {
      padding-top: 100px;

      .slide-title {
        margin-top: 67px;
      }
    }
    &.slide-2 {
      .slide-title {
        max-width: 600px;
      }
    }
    .slide-title {
      font-size: 50px;
      font-weight: 800;
      line-height: 1.1;
      text-transform: uppercase;
      margin: 34px 0 18px;
    }
    .slide-description {
      font-size: 16px;
      line-height: 24px;
      max-width: 500px;
    }
    .slide-actions {
      margin-top: 65px;

      a {
        display: inline-block;
        box-sizing: border-box;
        height: 60px;
        line-height: 56px;
        background-color: #f33e26;
        border: 2px solid #f33e26;
        padding: 0 25px;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        margin-right: 6px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
          background: none;
          color: #f33e26;
        }

        &:nth-child(2) {
          margin-right: 0;
          background: none;
          color: #f33e26;

          &:hover {
            background-color: #f33e26;
            color: #fff;
          }
        }
      }
    }
  }
  div.owl-nav {
    button {
      &:hover {
        span {
          background-color: #fff;

          &:before {
            color: #232323;
          }
        }
      }
      span {
        background-color: #232323;
        border: 2px solid #232323;

        &:before {
          color: #fff;
        }
      }
    }
  }
  .owl-carousel .owl-item .slide-main-image {
    display: none;
  }
  .slide-youtube {
    iframe {
      min-height: 295px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;

      @media only screen and (max-width: 767px) {
        position: relative;
      }
      @media only screen and (max-width: 480px) {
        min-height: 200px;
      }
    }
    .slide-actions {
      position: relative;
      z-index: 2;
    }
  }
  .quick-link-item-btn-video {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    line-height: 90px;
    background-color: #f33e26;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: all .3s;
    text-shadow: none;
    transform: translate3d(-50%,-50%,0);
    box-sizing: border-box;
    z-index: 1;

    &:hover {
      background-color: #a42918;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 30px;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      right: 0;
      margin: auto;
    }
  }
}

.page-bottom {
  background-color: #f6f6f6;
  overflow: hidden;
}
.page-subtitle {
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}
.homepage-brands-slider {
  .owl-carousel {
    max-width: 1300px;
    margin: 0 auto;
    position: static;
  }
  .page-subtitle {
    color: #313131;
    margin-top: 135px;
  }
  .homepage-brands-slider-wrapper {
    position: relative;
    margin: 60px auto 130px;
  }
}

.quick-links-switcher-wrapper {
  text-align: center;

  .homepage-quick-links-summer & {
    .switch {
      &:before {
        content: '\e908';
      }
      &:after {
        content: '\e90f';
      }
    }
  }
  .quick-links-switcher {
    display: inline-block;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 0 0 30px rgba(22, 22, 22, 0.08);
    padding: 10px;
    top: -35px;
    position: relative;
    z-index: 1;

    .switch-item-name {
      width: 195px;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-weight: 800;
      color: #c1c1c1;
      cursor: pointer;
      text-transform: uppercase;
      padding: 0 25px;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 101px;
    height: 50px;
    cursor: pointer;

    input {display:none;}
    &:before, &:after {
      font-family: 'icomoon';
      position: absolute;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      z-index: 1;
      top: 8px;
      font-size: 18px;
    }
    &:before {
      content: "\e902";
      color: #fff;
      left: 8px;
    }
    &:after {
      content: "\e903";
      color: #bcbcbc;
      right: 8px;
    }
  }
  .ski-active {
    .switch-item-name{
      &.ski-item-name {
        color: #313131;
      }
    }
  }
  .snowbord-active {
    .switch {
      &:before {
        color: #bcbcbc;
      }
      &:after {
        color: #fff;
      }
    }
    .switch-item-name{
      &.snowboard-item-name {
        color: #313131;
      }
    }
  }
  .switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ebebeb;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 25px;

    &:before {
      position: absolute;
      content: "";
      height: 35px;
      width: 35px;
      left: 8px;
      bottom: 8px;
      background-color: #f33e26;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
  input:checked + .switch-slider:before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
  }
}
.homepage-quick-links {
  max-width: 1300px;
  margin: 0 auto 50px;

  * {
    box-sizing: border-box;
  }
  .quick-links-col {
    width: 49%;
  }
  .quick-links-left {
    float: left;

    .quick-link-item {
      color: #313131;
    }
  }
  .quick-links-right {
    float: right;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);

    .quick-link-item {
      color: white;
    }
    .quick-link-name {
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    }
  }
  .quick-links-content {
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
    margin-top: 5px;

    > div {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      font-size: 16px;
    }
  }
}
.quick-link-item {
  height: 318px;
  background-color: #e9e9e9;
  display: block;
  overflow: hidden;
  margin-bottom: 4%;
  text-decoration: none;
  padding: 180px 8% 0;
  position: relative;
  -webkit-background-size: 637px 318px;
  background-size: 637px 318px;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:hover {
    text-decoration: none;
  }
  .quick-small-links & {
    width: 48%;
    float: left;
    color: inherit;
    text-decoration: none;
    padding-top: 28px;

    &:nth-child(2n) {
      float: right;
    }
    .quick-link-name {
      font-size: 35px;
      margin-top: 35px;
      margin-bottom: 8px;
      padding-right: 0;
    }
  }
  .quick-link-name {
    font-family: 'DKDirrrty';
    font-size: 50px;
    text-transform: uppercase;
    margin: 0 0 11px;
    padding-right: 70px;
  }
  .quick-link-description {
    padding-right: 70px;
  }
  .quick-link-item-btn {
    position: absolute;
    right: 55px;
    bottom: 55px;
    width: 60px;
    height: 60px;
    line-height: 56px;
    background-color: #f33e26;
    border: 2px solid #f33e26;
    color: #fff;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    text-shadow: none;

    &:hover {
      color: #f33e26;
      background: none;
    }
  }
  .quick-link-image-wrapper {
    display: table;
    width: 100%;

    .quick-link-image {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      height: 138px;

      img {
        max-height: 138px;
        max-width: 100%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
.homepage-reviews-wrapper {
  max-width: 1300px;
  margin: 0 auto -52px;

  * {
    box-sizing: border-box;
  }

  .homepage-reviews {
    background: #232323 url(../images/reviews-bg.jpg) 50% 50% no-repeat;
    color: #fff;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    padding: 70px 55px 50px;
    position: relative;
    z-index: 1;
  }
  .review-text {
    font-size: 25px;
    font-style: italic;
    line-height: 40px;
    margin-bottom: 12px;
  }
  .reviewer-name {
    font-size: 16px;
    line-height: 24px;
    color: #7b7b7b;

    a {
      color: #7b7b7b;
    }
  }
  .review-item-left {
    float: left;
    width: 150px;
    margin-top: -100px;
    text-align: center;
    background-color: #f1f1f1;
    color: #232323;
    padding-bottom: 10px;
  }
  .review-item-right {
    float: right;
    width: calc(100% - 215px);
  }
  img {
    display: block;
  }
  .feedback-logo {
    padding: 15px 25px;
  }
  .feedback-stars {
    line-height: 60px;
    background-color: #f33e26;
    color: #fff;
    font-size: 20px;
    letter-spacing: 4px;
  }
}
.cms-index-index {
  .columns {
    display: block;

    .column.main {
      padding-bottom: 0;
    }
  }
  .page.messages {
    .messages {
      &:first-child {
        margin-top: 15px;
      }
      &:last-child {
        margin-bottom: 55px;
      }
    }
  }
}

@media only screen and (max-width: 1480px) {
  .homepage-slider {
    .owl-carousel .owl-dots {
      max-width: 930px;
    }
    .container {
      max-width: 960px;
    }
  }
  .homepage-brands-slider {
    .owl-carousel div.owl-nav {
      display: none;
    }
  }
}
@media only screen and (max-width: 1120px) {
  .homepage-slider {
    min-height: 500px;

    .item {
      height: 500px;
      -webkit-background-size: cover;
      background-size: cover;
    }
    .container {
      max-width: 100%;
      padding-left: 85px;
      padding-right: 85px;
    }
    .owl-carousel .owl-dots {
      bottom: 10px;
      text-align: center;
    }
  }
  .quick-links-switcher-wrapper {
    margin-top: 40px;
    margin-bottom: 24px;

    .quick-links-switcher {
      top: 0;
    }
  }
  .homepage-reviews-wrapper {
    margin-bottom: 0;
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .page-subtitle {
    font-size: 20px;
  }
  .homepage-brands-slider {
    .page-subtitle {
      margin-top: 45px;
    }
    .homepage-brands-slider-wrapper {
      margin-bottom: 45px;
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 960px) {
  .quick-link-item {
    padding-top: 238px;
    -webkit-background-size: cover;
    background-size: cover;

    .quick-link-name {
      font-size: 30px;
      margin-bottom: 4px;
    }
    .quick-links-content > div {
      font-size: 14px;
    }
    .quick-link-item-btn {
      width: 44px;
      height: 44px;
      line-height: 40px;
      font-size: 12px;
      right: 25px;
      bottom: 25px;
    }
    .quick-small-links & .quick-link-name {
      margin-bottom: 4px;
      margin-top: 55px;
      font-size: 30px;
    }
  }
  .homepage-reviews-wrapper {
    .homepage-reviews {
      padding: 25px 15px;
    }
    .review-item-left {
      margin-top: -55px;
    }
  }
}
@media only screen and (max-width: 860px) {
  .homepage-quick-links .quick-links-col {
    float: none;
    width: 100%;
  }
  .quick-link-item {
    margin-bottom: 10px;
    height: 200px;
    padding-top: 120px;

    .quick-small-links & {
      width: 49%;
      padding-top: 20px;

      .quick-link-name {
         margin-top: 18px;
         font-size: 20px;
      }
      .quick-link-description {
        font-size: 10px;
      }
    }
    .quick-link-image-wrapper .quick-link-image {
      height: 100px;

      img {
        max-height: 100px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1120px) {
  .homepage-slider .item {
    .slide-title {
      color: #fff;
      text-shadow: 0 0 3px #313131;
    }
    .slide-description {
      color: #fff;
      text-shadow: 0 0 5px #313131;
    }
  }
}
@media only screen and (max-width: 767px) {
  .homepage-slider {
    min-height: 0;

    .item {
      padding-top: 0;
      background-image: none !important;
      height: auto;

      &.slide-1 {
        padding-top: 0;

        .slide-title {
          margin-top: 25px;
        }
      }
      .slide-title {
        font-size: 25px;
        margin-bottom: 8px;
        margin-top: 25px;
      }
      .slide-description {
        font-size: 14px;
      }
      .slide-actions {
        display: none;
        margin-top: 20px;

        a {
          height: 44px;
          line-height: 40px;
          font-size: 14px;
        }
      }
      .slide-logo {
        max-width: 130px;
      }
    }
    .owl-carousel {
      .owl-item .slide-main-image {
        display: block;
      }
      div.owl-nav {
        display: none;
      }
      .owl-dots {
        max-width: 100%;
        position: static;
        text-align: center;
        margin-top: 2px;
      }
    }
    .container {
      max-width: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 75px 0 0;
      height: auto;
    }
    .slide-content {
      padding: 0 15px;
    }
    .quick-link-item-btn-video {
      width: 60px;
      height: 60px;
      line-height: 60px;

      &:after {
        border-width: 12px 0 12px 20px;
        left: 8px;
      }
    }
  }
  .quick-links-switcher-wrapper {
    margin-top: 6px;
  }
  .slide-youtube {
    .container {
      display: none;
    }
  }
}
@media only screen and (max-width: 680px) {
  .homepage-slider .container {
    margin-top: -250px;
  }
}
@media only screen and (max-width: 600px) {
  .quick-links-switcher-wrapper .quick-links-switcher {
    display: block;
    padding-left: 5px;
    padding-right: 5px;

    .switch-item-name {
      font-size: 15px;
      width: 150px;
      padding: 0;
    }
  }
  .homepage-reviews-wrapper {
    .review-item-right {
      width: calc(100% - 175px);
    }
    .review-text {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .reviewer-name {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .quick-links-switcher-wrapper .quick-links-switcher .switch-item-name {
    line-height: 1;
    width: 60px;
    text-align: center;
    word-wrap: break-word;
    
    &:last-child {
      text-align: left;
      margin-left: 12px;
    }
  }
  .quick-link-item {
    .quick-small-links & {
      width: 48%;
    }
  }
}

.cms-index-index .page-main>.page-title-wrapper .page-title {
   display: none;
}
