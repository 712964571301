.block.newsletter {
    margin-top: 35px;

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            &:before {
                position: absolute;
            }
        }
    }

    input {
        height: 62px;
        border: none;
        background-color: $color-gray25;
        color: $color-white;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        padding: 0 25px;

        &::-webkit-input-placeholder {color: $color-gray30 !important;}
        &::-moz-placeholder {color: $color-gray30 !important;}
        &:-moz-placeholder {color: $color-gray30 !important;}
        &:-ms-input-placeholder {color: $color-gray30 !important;}
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;

        button {
            background-color: $color-gray25;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            height: 62px;
            line-height: 62px;
            font-size: 0;
            padding: 0;
            width: 50px;
            border: none;
            color: $color_white;

            &:before {
                font-family: 'icomoon';
                content: "\e906";
                font-size: 15px;
            }
        }
    }

    .action.subscribe {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -1px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        .field {
            .control {
                width: 100%;
            }
        }
    }
}
