//
//  Pager
//  _____________________________________________

//
//  When pager items have solid or don't have background
//  ---------------------------------------------

@mixin lib-pager(
    $_pager-label-display                      : none,
    $_pager-item-display                       : inline-block,
    $_pager-reset-spaces                       : true,
    $_pager-font-size                          : $font-size__s,
    $_pager-font-weight                        : $font-weight__bold,
    $_pager-line-height                        : 32px,
    $_pager-item-margin                        : 0 5px,
    $_pager-item-padding                       : 0 4px,
    $_pager-actions-padding                    : 0,

    // Page current
    $_pager-current-font-weight                : $font-weight__bold,
    $_pager-current-color                      : $primary__color,
    $_pager-current-background                 : inherit,
    $_pager-current-border                     : none,
    $_pager-current-gradient                   : inherit,
    $_pager-current-gradient-direction         : inherit,
    $_pager-current-gradient-color-start       : inherit,
    $_pager-current-gradient-color-end         : inherit,

    // Page item - text color
    $_pager-color                              : $link__color,
    $_pager-color-visited                      : $link__visited__color,
    $_pager-color-hover                        : $link__hover__color,
    $_pager-color-active                       : $link__active__color,

    // Page item - background
    $_pager-background                         : inherit,
    $_pager-background-visited                 : inherit,
    $_pager-background-hover                   : inherit,
    $_pager-background-active                  : inherit,

    // Page item - border
    $_pager-border                             : none,
    $_pager-border-visited                     : none,
    $_pager-border-hover                       : none,
    $_pager-border-active                      : none,

    // Page item - text decoration
    $_pager-text-decoration                    : none,
    $_pager-text-decoration-hover              : none,

    // Page item gradient
    $_pager-gradient                           : inherit,
    $_pager-gradient-direction                 : inherit,
    $_pager-gradient-color-start               : inherit,
    $_pager-gradient-color-end                 : inherit,
    $_pager-gradient-color-start-visited       : inherit,
    $_pager-gradient-color-end-visited         : inherit,
    $_pager-gradient-color-start-hover         : inherit,
    $_pager-gradient-color-end-hover           : inherit,
    $_pager-gradient-color-start-active        : inherit,
    $_pager-gradient-color-end-active          : inherit,

    // Page action item (previous-next)
    $_pager-action-text-decoration             : none,
    $_pager-action-text-decoration-hover       : none,

    // Page action item (previous-next) - default
    $_pager-action-color                       : $text__color__muted,
    $_pager-action-border                      : $border-width__base solid $border-color__base,
    $_pager-action-background                  : inherit,
    $_pager-action-gradient-color-start        : inherit,
    $_pager-action-gradient-color-end          : inherit,
    $_pager-action-text-decoration             : none,

    // Page action item (previous-next) - visited
    $_pager-action-color-visited               : $text__color__muted,
    $_pager-action-border-visited              : none,
    $_pager-action-background-visited          : inherit,
    $_pager-action-gradient-color-start-visited: inherit,
    $_pager-action-gradient-color-end-visited  : inherit,

    // Page action item (previous-next) - hover
    $_pager-action-color-hover                 : $text__color__muted,
    $_pager-action-border-hover                : $border-width__base solid $border-color__light,
    $_pager-action-background-hover            : inherit,
    $_pager-action-gradient-color-start-hover  : inherit,
    $_pager-action-gradient-color-end-hover    : inherit,
    $_pager-action-text-decoration-hover       : none,

    // Page action item (previous-next) - active
    $_pager-action-color-active                : $text__color__muted,
    $_pager-action-border-active               : none,
    $_pager-action-background-active           : inherit,
    $_pager-action-gradient-color-start-active : inherit,
    $_pager-action-gradient-color-end-active   : inherit,

    // Page action item (previous-next) - gradient
    $_pager-action-gradient                    : inherit,
    $_pager-action-gradient-direction          : inherit,

    // Page action icons
    $_pager-icon-use                           : true,
    $_pager-icon-previous-content              : $icon-prev,
    $_pager-icon-next-content                  : $icon-next,
    $_pager-icon-font                          : $icon-font,
    $_pager-icon-font-size                     : 46px,
    $_pager-icon-font-line-height              : $icon-font__line-height,
    $_pager-icon-font-margin                   : 0 0 0 -6px,
    $_pager-icon-font-vertical-align           : top,
    $_pager-icon-font-position                 : before,
    $_pager-icon-font-text-hide                : true
) {
    @include _lib-pager-label-display(  // To hide or to display label
        $_pager-label-display: $_pager-label-display,
        $_pager-font-size    : $_pager-font-size,
        $_pager-font-weight  : $_pager-font-weight,
        $_pager-line-height  : $_pager-line-height
    );

    .items {
        @include _lib-pager-inline-block-spaces-container();
        @include lib-list-reset-styles();
        display: block;
        font-weight: $_pager-font-weight;
        position: relative;
        padding: 0 35px;
    }

    .item {
        @include _lib-pager-inline-block-spaces-item(
            $_pager-font-size  : $_pager-font-size,
            $_pager-line-height: $_pager-line-height
        );
        margin: $_pager-item-margin;
        display: $_pager-item-display;

        .label {
            @include lib-visually-hidden();
        }
        &.pages-item-previous {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
        }
        &.pages-item-next {
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
        }
    }

    a.page {
        color: #bbbbbb;
        box-sizing: border-box;
        display: block;
        width: 44px;
        height: 44px;
        line-height: 40px;
        border: 2px solid #f6f6f6;
        padding: 0;
        text-align: center;
        font-size: 16px;
        background-color: #f6f6f6;

        &:hover, &:active {
            color: #151515;
            border: 2px solid #151515;
            text-decoration: $_pager-text-decoration-hover;
        }
    }

    strong.page {
        color: #fff;
        box-sizing: border-box;
        display: block;
        width: 44px;
        height: 44px;
        line-height: 40px;
        border: 2px solid #151515;
        padding: 0;
        text-align: center;
        font-size: 16px;
        background-color: #151515;
    }

    .action {
        box-sizing: border-box;
        display: block;
        width: 34px;
        height: 44px;
        line-height: 40px;
        font-size: 0;
        text-align: center;
        text-decoration: none;

        &.next, &.previous {
            &:before {
                font-family: 'icomoon';
                color: #151515;
                font-size: 12px;
            }
        }
        &.next {
            &:before {
                content: '\e906';
            }
        }
        &.previous {
            &:before {
                content: '\e905';
            }
        }
    }
}

//  Delete spaces between elements when pager items have display: inline-block
@mixin _lib-pager-inline-block-spaces-container() {
    @if $pager-reset-spaces == true and $pager-item__display == inline-block {
        @include lib-inline-block-space-container();
        white-space: nowrap;
    }
}

@mixin _lib-pager-inline-block-spaces-item(
    $_pager-font-size  : $_pager-font-size,
    $_pager-line-height: $_pager-line-height
) {
    @if $pager-reset-spaces == true and $pager-item__display == inline-block {
        @include lib-inline-block-space-item(
            $_font-size  : $_pager-font-size,
            $_line-height: $_pager-line-height
        );
    }
}

// Display or hide "page" label
@mixin _lib-pager-label-display(
    $_pager-label-display: $_pager-label-display,
    $_pager-font-size    : $_pager-font-size,
    $_pager-font-weight  : $_pager-font-weight,
    $_pager-line-height  : $_pager-line-height
) {
    @if $_pager-label-display != none {
        > .label {
            display: $_pager-label-display;
            font-weight: $_pager-font-weight;
            @include lib-inline-block-space-item(
                $_font-size: $_pager-font-size,
                $_line-height: $_pager-line-height
            );

            &:after {
                content: ': ';
            }
        }
    }
}

@mixin _lib-pager-label-display(
    $_pager-label-display: $_pager-label-display,
    $_pager-font-size    : $_pager-font-size,
    $_pager-font-weight  : $_pager-font-weight,
    $_pager-line-height  : $_pager-line-height
) {
    @if $_pager-label-display == none {
        & > .label {
            @include lib-visually-hidden();
        }
    }
}

@mixin _lib-pager-icon (
    $_pager-icon-use          : $_pager-icon-use,
    $_icon-font-content       : '',
    $_icon-font               : $_pager-icon-font,
    $_icon-font-size          : $_pager-icon-font-size,
    $_icon-font-line-height   : $_pager-icon-font-line-height,
    $_icon-font-color         : $_pager-action-color,
    $_icon-font-color-visited : $_pager-action-color-visited,
    $_icon-font-color-hover   : $_pager-action-color-hover,
    $_icon-font-color-active  : $_pager-action-color-active,
    $_icon-font-margin        : $_pager-icon-font-margin,
    $_icon-font-vertical-align: $_pager-icon-font-vertical-align,
    $_icon-font-position      : $_pager-icon-font-position,
    $_icon-font-text-hide     : $_pager-icon-font-text-hide
) {
    @if $_pager-icon-use == true {
        @if $_icon-font-position == before {
            &:visited {
                &:before {
                    color: $_icon-font-color-visited;
                }
            }

            &:active {
                &:before {
                    color: $_icon-font-color-active;
                }
            }
            @include lib-icon-font(
                $_icon-font-content       : $_icon-font-content,
                $_icon-font               : $_icon-font,
                $_icon-font-size          : $_icon-font-size,
                $_icon-font-line-height   : $_icon-font-line-height,
                $_icon-font-color         : $_icon-font-color,
                $_icon-font-color-hover   : $_icon-font-color-hover,
                $_icon-font-color-active  : $_icon-font-color-active,
                $_icon-font-margin        : $_icon-font-margin,
                $_icon-font-vertical-align: $_icon-font-vertical-align,
                $_icon-font-position      : $_icon-font-position,
                $_icon-font-text-hide     : $_icon-font-text-hide
            );
        }
        @else if $_icon-font-position == after {
            @include lib-icon-font(
                $_icon-font-content       : $_icon-font-content,
                $_icon-font               : $_icon-font,
                $_icon-font-size          : $_icon-font-size,
                $_icon-font-line-height   : $_icon-font-line-height,
                $_icon-font-color         : $_icon-font-color,
                $_icon-font-color-hover   : $_icon-font-color-hover,
                $_icon-font-color-active  : $_icon-font-color-active,
                $_icon-font-margin        : $_icon-font-margin,
                $_icon-font-vertical-align: $_icon-font-vertical-align,
                $_icon-font-position      : $_icon-font-position,
                $_icon-font-text-hide     : $_icon-font-text-hide
            );
            &:visited:after {
                color: $_icon-font-color-visited;
            }
            &:active:after {
                color: $_icon-font-color-active;
            }
        }
    }
}
