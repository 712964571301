//
//  Table variables
//  _____________________________________________

$table__width: 100% !default;
$table__margin-bottom:  $indent__base !default;
$table__background-color: inherit !default;
$table__border-color: $border-color__base !default;
$table__border-width: $border-width__base !default;
$table__border-style: solid !default;

$table-cell__padding-horizontal: $indent__s !default;
// $table-cell__padding-vertical: 8px !default;
$table-td__color: inherit !default;

//
//  Tables
//  ---------------------------------------------

$table-cell__padding-vertical: 11px !default;
$table-cell__hover__background-color: $panel__background-color !default;
$table-cell-stripped__background-color: $table-cell__hover__background-color !default;
$table-responsive-th__background-color: $table-cell-stripped__background-color !default;
$table-responsive-cell__padding: $indent__xs $indent__s !default;
