//
//  Navigation
//  ---------------------------------------------

@mixin lib-main-navigation(
    $_nav__indent-side                       : 15px,
    $_nav-background-color                   : $secondary__color__light,
    $_nav-border                             : none,
    $_nav-level0-font-size                   : 16px,
    $_nav-level0-font-weight                 : $font-weight__bold,
    $_nav-level0-item-line-height            : inherit,
    $_nav-level0-item-margin                 : 0,
    $_nav-level0-item-padding                : 8px $indent__xl 8px 15px,
    $_nav-level0-text-transform              : uppercase,

    $_nav-level0-item-background-color       : inherit,
    $_nav-level0-item-border                 : 1px solid $color-gray82,
    $_nav-level0-item-color                  : $color-gray34,
    $_nav-level0-item-text-decoration        : none,

    $_nav-level0-item-background-color-active: inherit,
    $_nav-level0-item__active__border-color  : $color-orange-red1,
    $_nav-level0-item__active__border-style  : solid,
    $_nav-level0-item__active__border-width  : 0 0 0 8px,
    $_nav-level0-item-color-active           : inherit,
    $_nav-level0-item-text-decoration-active : inherit,

    $_submenu-background-color               : inherit,
    $_submenu-border                         : none,
    $_submenu-font-size                      : inherit,
    $_submenu-font-weight                    : $font-weight__regular,
    $_submenu-line-height                    : 1.3,
    $_submenu-item__padding-top              : $indent__s,
    $_submenu-item__padding-right            : 0,
    $_submenu-item__padding-bottom           : $indent__s,
    $_submenu-item__padding-left             : 15px,

    $_submenu-item-background-color          : inherit,
    $_submenu-item-border                    : none,
    $_submenu-item-color                     : $color-gray34,
    $_submenu-item-text-decoration           : inherit,

    $_submenu-item-background-color-active   : inherit,
    $_submenu-item__active__border           : 8px,
    $_submenu-item__active__border-color     : $color-orange-red1,
    $_submenu-item__active__border-style     : solid,
    $_submenu-item__active__border-width     : 0 0 0 8px,
    $_submenu-item-color-active              : inherit,
    $_submenu-item-text-decoration-active    : inherit
) {
    .navigation {
        background: $_nav-background-color;
        border: $_nav-border;
        box-sizing: border-box;

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
        }

        a {
            display: block;
            padding: $_submenu-item__padding-top $_submenu-item__padding-right $_submenu-item__padding-bottom $_submenu-item__padding-left;
        }

        a,
        a:hover {
            color: $_nav-level0-item-color;
            text-decoration: $_nav-level0-item-text-decoration;
        }

        .level0 {
            border-top: $_nav-level0-item-border;
            font-size: $_nav-level0-font-size;

            & > .level-top {
                background: $_nav-level0-item-background-color;
                font-weight: $_nav-level0-font-weight;
                line-height: $_nav-level0-item-line-height;
                padding: $_nav-level0-item-padding;
                text-transform: $_nav-level0-text-transform;
                word-wrap: break-word;
            }

            &.active {
                .all-category {
                    .ui-state-focus {
                        background: $_nav-level0-item-background-color-active;
                        border-color: $_nav-level0-item__active__border-color;
                        border-style: $_nav-level0-item__active__border-style;
                        border-width: $_nav-level0-item__active__border-width;
                        color: $_nav-level0-item-color-active;
                        padding-left: $_nav__indent-side - $_submenu-item__active__border;
                        text-decoration: $_nav-level0-item-text-decoration-active;
                        display: inline-block;
                    }
                }
            }

            & > .level1 {
                font-weight: $font-weight__semibold;
            }

            &.active,
            &.has-active { // ToDo UI: remove "has_active" here, when mobile navigation default open state is implemented
                & > a:not(.ui-state-active) {
                    background: $_nav-level0-item-background-color-active;
                    border-color: $_nav-level0-item__active__border-color;
                    border-style: $_nav-level0-item__active__border-style;
                    border-width: $_nav-level0-item__active__border-width;
                    color: $_nav-level0-item-color-active;
                    text-decoration: $_nav-level0-item-text-decoration-active;

                    span:not(.ui-menu-icon) {
                        margin-left: -$_submenu-item__active__border;
                    }
                }
            }
        }

        li.level0 {
            &:last-child {
                border-bottom: $_nav-level0-item-border;
            }
        }

        .submenu {
            & > li {
                word-wrap: break-word;
            }

            &:not(:first-child) {
                background: $_submenu-background-color;
                border: $_submenu-border;
                font-size: $_submenu-font-size;
                font-weight: $_submenu-font-weight;
                line-height: $_submenu-line-height;
                left: auto;
                overflow-x: hidden;
                padding: 0;
                position: relative;
                top: auto !important;
                transition: left 0.3s ease-out;

                & > li {
                    & > a {
                        padding-left: $_nav__indent-side;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    display: block;
                    padding-left: $_submenu-item__padding-left;

                    & > li {
                        margin: 0;

                        a {
                            background: $_submenu-item-background-color;
                            border: $_submenu-item-border;
                            color: $_submenu-item-color;
                            text-decoration: $_submenu-item-text-decoration;
                            display: block;
                            line-height: normal;
                        }
                    }
                }

                &.expanded {
                    display: block !important;
                    padding-right: 0;
                    top: 0 !important;
                }

                .active {
                    & > a {
                        background: $_submenu-item-background-color-active;
                        border-color: $_submenu-item__active__border-color;
                        border-style: $_submenu-item__active__border-style;
                        border-width: $_submenu-item__active__border-width;
                        color: $_submenu-item-color-active;
                        padding-left: $_nav__indent-side - $_submenu-item__active__border;
                        text-decoration: $_submenu-item-text-decoration-active;
                    }
                }

                .level1 {
                    &.active {
                        & > a {
                            padding-left: $_nav__indent-side - $_submenu-item__active__border;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@mixin lib-main-navigation-desktop(
    $_nav-background-color                   : $color-gray94,
    $_nav-border                             : none,
    $_nav-font-size                          : inherit,
    $_nav-font-weight                        : $font-weight__bold,

    $_nav-level0-item-line-height            : 53px,
    $_nav-level0-item-margin                 : 0 $indent__s 0 0,
    $_nav-level0-item-padding                : 0 12px,

    $_nav-level0-item-background-color       : inherit,
    $_nav-level0-item-border                 : none,
    $_nav-level0-item-color                  : $color-gray34,
    $_nav-level0-item-text-decoration        : none,

    $_nav-level0-item-background-color-hover : inherit,
    $_nav-level0-item-border-hover           : none,
    $_nav-level0-item-color-hover            : $primary__color,
    $_nav-level0-item-text-decoration-hover  : none,

    $_nav-level0-item-background-color-active: inherit,
    $_nav-level0-item__active__border-color  : $color-orange-red1,
    $_nav-level0-item__active__border-style  : solid,
    $_nav-level0-item__active__border-width  : 0 0 3px,
    $_nav-level0-item-color-active           : $primary__color,
    $_nav-level0-item-text-decoration-active : none,

    $_submenu-background-color               : $page__background-color,
    $_submenu-border-width                   : $border-width__base,
    $_submenu-border-style                   : solid,
    $_submenu-border-color                   : $border-color__base,
    $_submenu-box-shadow                     : 0 $indent__xs $indent__xs rgba(0, 0, 0, 0.19),
    $_submenu-font-size                      : inherit,
    $_submenu-font-weight                    : $font-weight__bold,
    $_submenu-min-width                      : 230px,
    $_submenu-padding                        : 15px 0,

    $_submenu-arrow                          : true, // [true|false],
    $_submenu-arrow-size                     : 10px,
    $_submenu-arrow-left                     : 20px,

    $_submenu-item-padding                   : 8px $indent__base,
    $_submenu-item-background-color          : inherit,
    $_submenu-item-border                    : none,
    $_submenu-item-color                     : $primary__color,
    $_submenu-item-text-decoration           : inherit,

    $_submenu-item__hover__background-color  : $secondary__color,
    $_submenu-item-border-hover              : none,
    $_submenu-item-color-hover               : $primary__color,
    $_submenu-item-text-decoration-hover     : none,

    $_submenu-item-background-color-active   : inherit,
    $_submenu-item__active__border-color     : $color-orange-red1,
    $_submenu-item__active__border-style     : solid,
    $_submenu-item__active__border-width     : 0 0 0 3px,
    $_submenu-item-color-active              : inherit,
    $_submenu-item-text-decoration-active    : inherit
) {

    .navigation {
        background: $_nav-background-color;
        border: $_nav-border;
        font-size: $_nav-font-size;
        font-weight: $_nav-font-weight;
        height: inherit;
        left: auto;
        overflow: inherit;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 3;

        &:empty {
            display: none;
        }

        ul {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            position: relative;
            font-weight: $_nav-font-weight;
        }

        li.level0 {
            border-top: none;
        }

        .level0 {
            margin: $_nav-level0-item-margin;
            display: inline-block;
            position: relative;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }

            & > .level-top {
                background: $_nav-level0-item-background-color;
                border: $_nav-level0-item-border;
                color: $_nav-level0-item-color;
                line-height: $_nav-level0-item-line-height;
                padding: $_nav-level0-item-padding;
                text-decoration: $_nav-level0-item-text-decoration;
                box-sizing: border-box;
                position: relative;

                &:hover,
                &.ui-state-focus {
                    background: $_nav-level0-item-background-color-hover;
                    border: $_nav-level0-item-border-hover;
                    color: $_nav-level0-item-color-hover;
                    text-decoration: $_nav-level0-item-text-decoration-hover;
                }
            }

            &.active,
            &.has-active {
                & > .level-top {
                    background: $_nav-level0-item-background-color-active;
                    border-color: $_nav-level0-item__active__border-color;
                    border-style: $_nav-level0-item__active__border-style;
                    border-width: $_nav-level0-item__active__border-width;
                    color: $_nav-level0-item-color-active;
                    text-decoration: $_nav-level0-item-text-decoration-active;
                    display: inline-block;
                }
            }

            &.parent:hover > .submenu {
                overflow: visible !important;
            }

            .submenu {
                background: $_submenu-background-color;
                border: $_submenu-border-width $_submenu-border-style $_submenu-border-color;
                box-shadow: $_submenu-box-shadow;
                font-size: $_submenu-font-size;
                font-weight: $_submenu-font-weight;
                min-width: $_submenu-min-width;
                padding: $_submenu-padding;
                display: none;
                left: 0;
                margin: 0 !important;
                padding: 0;
                position: absolute;
                z-index: 1;

                @include _lib-submenu-arrow (
                    $_submenu-arrow,
                    $_bg    : $_submenu-background-color,
                    $_border: $_submenu-border-color,
                    $_size  : $_submenu-arrow-size,
                    $_left  : $_submenu-arrow-left
                );

                a {
                    display: block;
                    line-height: inherit;
                    background: $_submenu-item-background-color;
                    border: $_submenu-item-border;
                    color: $_submenu-item-color;
                    padding: $_submenu-item-padding;
                    text-decoration: $_submenu-item-text-decoration;

                    &:hover,
                    &.ui-state-focus {
                        background: $_submenu-item__hover__background-color;
                        border: $_submenu-item-border-hover;
                        color: $_submenu-item-color-hover;
                        text-decoration: $_submenu-item-text-decoration-hover;
                    }
                }

                .active > a {
                    background: $_submenu-item-background-color-active;
                    border-color: $_submenu-item__active__border-color;
                    border-style: $_submenu-item__active__border-style;
                    border-width: $_submenu-item__active__border-width;
                    color: $_submenu-item-color-active;
                    text-decoration: $_submenu-item-text-decoration-active;
                }

                .submenu {
                    top: 0 !important;
                    left: 100% !important;
                }

                .submenu-reverse {
                    left: auto !important;
                    right: 100%;
                }
            }

            &.more {
                position: relative;
                @include lib-icon-font(
                    $icon-pointer-down,
                    $_icon-font-size: 26px,
                    $_icon-font-position: after
                );

                &:before {
                    display: none;
                }

                &:after {
                    cursor: pointer;
                    padding: 8px 12px;
                    position: relative;
                    z-index: 1;
                }

                &:hover > .submenu {
                    overflow: visible !important;
                }

                li {
                    display: block;
                }
            }
        }
    }
}

//  Submenu arrow
@mixin _lib-submenu-arrow(
    $_submenu-arrow,
    $_bg,
    $_border,
    $_size,
    $_left
) {
    @if $_submenu-arrow == true {
        @if iscolor($_bg) and iscolor($_border) {
            $_outer-size: $_size + 1;
            $_outer-left: $_left - 1;
            margin-top: $_outer-size;

            & > ul {
                margin-top: $_outer-size;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                }

                &:before {
                    color: $_bg;
                    left: $_left;
                    top: -$_size * 2;
                    @include lib-arrow(up, $_size, $_bg);
                    z-index: 4;
                }

                &:after {
                    @include lib-arrow(up, $_outer-size, $_border);
                    color: $_border;
                    left: $_outer-left;
                    top: -$_outer-size * 2;
                    z-index: 3;
                }
            }
        }
        @else if iscolor($_bg) and iscolor($_border) {
            margin-top: -$_size;

            & > ul {
                margin-top: $_size;

                &:before {
                    @include lib-arrow(up, $_size, $_bg);
                    color: $_bg;
                    left: $_left;
                    top: -$_size * 2;
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }
        @else if iscolor($_border) and iscolor($_bg) {
            margin-top: -$_size;

            & > ul {
                margin-top: $_size;

                &:before {
                    @include lib-arrow(up, $_size, $_border);
                    color: $_border;
                    left: $_left;
                    top: -$_size * 2;
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }
    }
}
