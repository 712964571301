@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800');

@font-face {
  font-family: 'DKDirrrty';
  src: url('../fonts/DKDirrrty.woff2') format('woff2'),
  url('../fonts/DKDirrrty.woff') format('woff'),
  url('../fonts/DKDirrrty.ttf') format('truetype');
}

.clearfix {
  &:before, &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.no-display {
  display: none !important;
}
.price-box {
  .price-label {
    display: none;
  }
}
.usp-sidebar {
  padding: 0;
  margin: 0;
  list-style-type: none;


  li {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    margin-bottom: 12px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      background-color: #6dd49b;
      color: #1d7042;
      border-radius: 50%;
      text-align: center;
      font-size: 8px;
      font-weight: bold;
      margin-right: 6px;
      vertical-align: middle;
    }
    strong {
      text-transform: uppercase;
    }
    em {
      display: block;
      margin-top: 8px;
    }
    br {
      display: none;
    }
    img {
      margin-top: 10px;
    }
  }
}
.page-title-wrapper {
  .page-title {
    font-family: 'DKDirrrty';
  }
}
.account {
  .page-title {
    h1 {
      font-family: 'DKDirrrty';
    }
  }
}
.cms-page-view {
  .page-header {
    margin-bottom: 20px;

    @media only screen and (max-width: 860px) {
      margin-bottom: 40px;
    }
  }
}
.block {
  &.account-nav {
    margin-bottom: 0;
  }
}