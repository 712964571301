.category-view {
  text-align: center;
  overflow: hidden;

  &.has-category-image {
    color: #fff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;

    > .container {
      display: table;
      width: 100%;

      > div {
        display: table-cell;
        vertical-align: middle;
        height: 311px;
      }
    }
    .page-title {
      margin-top: 10px;
    }
    .category-description {
      margin-bottom: 15px;
    }
  }

  .page-title {
    font-family: 'DKDirrrty';
    font-size: 70px;
    margin: 25px 0;
  }
}
.catalog-category-view {
  .page-main {
    padding-top: 85px;
  }
  .columns .column.main {
    padding-bottom: 0;
  }
  .category-description {
    line-height: 24px;
    margin: 40px auto 35px;
    border-top: 2px solid #d6d6d6;
    padding-top: 40px;

    .page-subtitle {
      margin-top: 0;
    }
    p {
      margin: 0;
    }
  }
}
.products-grid {
  .product-label-container {
    position: absolute;
    z-index: 2;
    top: 26px;
    left: 29px;
  }
}
.categoory-top-navigation-wrapper {
  background-color: #f6f6f6;
}
.catagory-top-navigation {
  list-style-type: none;
  padding: 0;
  max-width: 1450px;
  margin: 0 auto;
  font-size: 0;

  > li {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    margin: 45px 0 -20px;
    padding: 0 10px;

    .category-image-wrapper {
      display: table;
      width: 100%;
      margin-bottom: 30px;
    }
    .category-image {
      display: table-cell;
      vertical-align: middle;
      height: 100px;

      img {
        max-width: 120px;
        max-height: 100px;
        display: block;
        margin: 0 auto;
      }
    }
    .category-name {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      border: 2px solid rgba(21, 21, 21, 0.1);
      padding: 0 15px;
      color: #151515;
      font-size: 16px;
      font-weight: 600;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        background-color: #f33e26;
        color: #fff;

        i {
          color: #fff;
        }
      }

      > * {
        vertical-align: middle;
        display: inline-block;
      }
      i {
        color: #e7e7e7;
        font-size: 7px;
        margin-left: 16px;
      }
    }
  }
}
.sorter-options-list {
  .sorter-option-item {
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    color: #919191;
    margin-right: 38px;

    span {
      display: inline-block;
      line-height: 24px;
      border-bottom: 2px solid transparent;
    }

    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: #232323;

      span {
        border-color: #f33e26;
      }
    }
  }
}
.page-layout-2columns-left, .page-layout-2columns-right {
  .sidebar {
    width: 23.5%;

    &.sidebar-additional {
      padding: 18px;
    }
  }
  .column.main {
    width: 74.75%;
  }
}
.page-products {
  .columns {
    padding-bottom: 115px;
  }
}
.product-label-container {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  background-color: rgba(35, 35, 35, 0.8);
  border-radius: 11px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;

  .product-label {
    vertical-align: baseline !important;
  }
}
.product-item-inner {
  overflow: hidden;
  display: none;
}
.product-attributes {
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px;
  font-size: 12px;

  li {
    position: relative;
    padding-left: 10px;
    margin: 0 0 4px;

    &:before {
      content: "+";
      position: absolute;
      left: 0;
    }
    > span {
      vertical-align: middle;
    }
  }
}

.category-bottom-container {
  border-top: 2px solid #d6d6d6;
  margin-top: 40px;
  padding-top: 50px;

  .page-subtitle {
    margin-top: 0;
    margin-bottom: 70px;
  }
}
.category-bottom-quick-link-item {
  float: left;
  width: 48.75%;
  margin-right: 2.5%;
  margin-bottom: 2.5%;
  background-color: #d6d6d6;
  color: #fff;
  height: 362px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  &:nth-child(2n) {
    margin-right: 0;
  }

  .quick-link-item-btn {
    position: absolute;
    right: 55px;
    bottom: 55px;
    width: 60px;
    height: 60px;
    line-height: 56px;
    background-color: #f33e26;
    border: 2px solid #f33e26;
    color: #fff;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-shadow: none;
    box-sizing: border-box;

    &:hover {
      color: #f33e26;
      background: none;
    }

    &.quick-link-item-btn-video {
      &:before {
        content: '';
        width: 35px;
        height: 35px;
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: #a42918;
        border: 5px solid #c93925;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 6px;
        border-color: transparent transparent transparent #ffffff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 2px;
        right: 0;
        margin: auto;
      }
    }
  }
  .quick-link-item-label {
    position: absolute;
    top: 45px;
    left: 60px;
    height: 22px;
    line-height: 22px;
    background-color: rgba(35, 35, 35, 0.8);
    border-radius: 11px;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
  }
  .quick-link-name {
    font-family: 'DKDirrrty';
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .quick-link-description {
    font-size: 16px;
  }
  .category-bottom-quick-link-text {
    position: absolute;
    left: 60px;
    bottom: 50px;
    width: calc(100% - 210px);
  }
}
.youtube iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.filter-title {
  display: none;
}
.price-inputs-wrapper {
  display: table;
  width: 100%;
  margin-top: 25px;

  > div {
    display: table-cell;
    vertical-align: middle;
  }
  .field {
    position: relative;
    width: 50%;

    .currency-symbol {
      position: absolute;
      top: 10px;
      left: 15px;
    }
  }
  input {
    height: 38px;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    padding-left: 26px;
  }
  .field-price-separator {
    padding: 0 12px;
  }
}
#aw-layered-nav-popup {
  display: none;
}
.mobile-actions-bar {
  display: none;
  float: right;
  font-size: 0;

  a {
    display: inline-block;
    height: 50px;
    line-height: 46px;
    border: 2px solid #151515;
    font-size: 18px;
    color: #151515;
    font-weight: 700;
    background-color: #fff;
    box-sizing: border-box;
    margin-left: 20px;
    text-decoration: none;
    padding: 0 24px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    text-align: center;
    
    &:hover {
      background-color: #151515;
      color: #fff;
    }
    &.show-filters {
      background-color: #151515;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #151515;
      }
    }
    &:after {
      content: '...';
      color: #d3d3d3;
      font-size: 26px;
      display: inline-block;
      vertical-align: top;
      height: 6px;
      line-height: 1;
      margin-top: 4px;
      margin-left: 20px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.mobile-sorter-header {
  display: none;
  background-color: #e5e5e5;
  height: 50px;
  overflow: hidden;
  position: relative;
  padding: 0 60px;

  h3 {
    line-height: 50px;
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
  .close-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #232323;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 20px;
      height: 3px;
      background-color: #fff;
      margin: auto;
    }
    &:before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}
.products-number {
  font-size: 30px;
  color: #232323;
  font-weight: 700;
}
.bottom-toolbar {
  .toolbar-products {
    > * {
      display: none;
    }
    .pages {
      display: block;
    }
  }
}
.block.filter {
  margin-bottom: 0;
}
.close-filters-mobile-wrapper {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 50px;
  overflow: hidden;
  padding: 0 50px;
  text-align: center;
  box-shadow: 0 0 10px #888888;
  box-sizing: border-box;

  .close-filters-x {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #232323;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 20px;
      height: 3px;
      background-color: #fff;
      margin: auto;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .close-filters-btn {
    display: inline-block;
    background-color : #f33e26;
    color: #fff;
    padding: 6px 15px;
    margin-top: 7px;
    transition: all 0.3s;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      background: #f33e26;
      transition: all 0.3s;
    }
  }
}
.category-top-description-wrapper {
  margin-bottom: 20px;

  &.active {
    .category-top-description {
      max-height: 2000px;
    }
  }
}
.category-top-description {
  overflow: hidden;
  max-height: 112px;
  transition: all 0.3s;

  .page-subtitle {
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: 'DKDirrrty';
  }
}

@media only screen and (max-width: 1480px) {
  .catagory-top-navigation > li .category-name {
    padding: 0 12px;
    font-size: 14px;

    i {
      display: none;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .categoory-top-navigation-wrapper {
    display: none;
  }
  .catalog-category-view .page-main {
    padding-top: 30px;
  }
  .category-bottom-quick-link-item {
    height: 318px;

    .category-bottom-quick-link-text {
      left: 8%;
      bottom: 11%;
      width: calc(100% - 115px);
    }
    .quick-link-description {
      display: none;
    }
    .quick-link-name {
      font-size: 28px;
      margin-bottom: 0;
    }
    .quick-link-item-btn {
      width: 50px;
      height: 50px;
      line-height: 46px;
      right: 8%;
      bottom: 8%;

      &.quick-link-item-btn-video:before {
        width: 22px;
        height: 22px;
      }
    }
    .quick-link-item-label {
      top: 8%;
      left: 8%;
    }
  }
  .page-products .columns {
    padding-bottom: 75px;
  }
}
@media only screen and (max-width: 1023px) {
  .page-layout-2columns-left, .page-layout-2columns-right {
    .sidebar {
      width: 100%;
    }
    .column.main {
      width: 100%;

      .product-items {
        margin-left: 0;
      }
    }
  }
  .products-grid {
    .product-item-photo {
      margin-top: 0 !important;
    }
    .product-item-inner {
      display: none !important;
    }
    .product-image-container {
      width: 100% !important;
      display: table;
    }
    .product-image-wrapper {
      padding: 0 !important;
      display: table-cell;
      height: 260px;
      vertical-align: middle;
    }
    .product-image-photo {
      position: static;
    }
    .product-item-details {
      background: none !important;
    }
  }
  #layered-filter-block {
    display: none;
  }
  .category-bottom-container {
    .page-subtitle {
      font-size: 30px;
      margin-bottom: 60px;
    }
  }
  .mobile-actions-bar {
    display: block;
  }
  .toolbar-sorter {
    display: none;
  }
  .toolbar-products {
    margin-bottom: 40px;
  }
  .toolbar-amount {
    font-size: 20px;
    margin-top: 8px;

    .toolbar-number {
      font-size: 31px;
    }
  }
  .sorter-opened {
    overflow: hidden;

    &.page-products .columns {
      z-index: 99;
    }
    .toolbar-sorter {
      display: block;
      position: fixed;
      background-color: #f6f6f6;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 99;
      text-align: left;
      overflow: auto;
      box-sizing: border-box;
      padding-top: 0;
    }
    .sorter-options-list {
      padding: 35px 15px 15px 40px;

      .sorter-option-item {
        font-size: 16px;
        display: block;
        margin: 0 0 25px;
      }
    }
  }
  .filter-opened {
    overflow: hidden;

    &.page-products .columns {
      z-index: 99;
    }
    #layered-filter-block {
      display: block;
      position: fixed;
      background-color: #f6f6f6;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 99;
      overflow: auto;
      box-sizing: border-box;
      padding-top: 50px;
      padding-bottom: 35px;
    }
    .close-filters-mobile-wrapper {
      display: block;
    }
  }
  .mobile-sorter-header {
    display: block;
  }
  .toolbar-products .pages {
    border-top: 2px solid #eaeaea;
    padding-top: 42px;
    margin-top: 15px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 960px) {
  .category-view {
    .page-title {
      font-size: 52px;
      margin: 0;
    }
    &.has-category-image > .container > div {
      height: 200px;
    }
  }
  .products-grid .product-item {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 860px) {
  .category-view {
    padding-top: 40px;

    &.has-category-image {
      padding-top: 0;
    }
  }
}
@media only screen and (max-width: 767px) {
  .category-view {
    .page-title {
      font-size: 30px;
    }
    &.has-category-image > .container > div {
      height: 175px;
    }
  }
  .products-grid {
    .product-item {
      margin-bottom: 4%;
    }
  }
  .category-bottom-quick-link-item {
    height: 200px;

    .quick-link-name {
      font-size: 20px;
      line-height: 1.1;
    }
  }
}
@media only screen and (max-width: 600px) {
  .category-view {
    &.has-category-image > .container > div {
      height: 125px;
    }
  }
  .products-grid {
    .product-image-wrapper {
      height: 180px;

      .product-image-photo {
        width: auto;
        max-height: 180px;
      }
    }
  }
  .category-bottom-quick-link-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;

    .quick-link-item-btn {
      width: 44px;
      height: 44px;
      line-height: 40px;

      i {
        font-size: 12px;
      }
    }
    .category-bottom-quick-link-text {
      bottom: 13%;
    }
  }
  .category-bottom-container .page-subtitle {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .page-products .columns {
    padding-bottom: 45px;
  }
  .mobile-actions-bar {
    float: none;
    width: 100%;

    a {
      height: 44px;
      line-height: 40px;
      padding: 0 10px;
      width: 48%;
      font-size: 14px;
      margin-left: 0;

      &.show-filters {
        margin-right: 4%;
      }
      &:after {
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }
  .toolbar-amount {
    text-align: center;
    font-size: 14px;
    margin-top: 25px;

    .toolbar-number {
      font-size: 30px;
    }
  }
  .catalog-category-view .page-main {
    padding-top: 20px;
  }
  .toolbar-products {
    margin-bottom: 22px;

    + .product {
      margin-top: 22px;
    }
  }
  .toolbar-products {
    .pages {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 22px;
    }
    & + .products {
      margin-bottom: 22px;
    }
  }
  .close-filters-mobile-wrapper {
    padding-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .products-grid {
    .product-item-name a.product-item-link {
      font-size: 12px;
      line-height: 17px;
    }
    .product-label-container {
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
    }
    .item.product.product-item {
      padding-top: 15px;
    }
    .product-item-details {
      padding-top: 5px;
    }
  }
  .product-item {
    .price-box .price {
      font-size: 16px;
    }
    .old-price {
      margin-right: 5px;

      .price {
        font-size: 11px;
      }
    }
  }
}