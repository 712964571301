.owl-carousel {
  .owl-dots {
    position: absolute;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 205px;

    .owl-dot {
      margin-right: 8px;

      span {
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        opacity: 0.1;
        background-color: #232323;
      }

      &.active span {
        opacity: 1;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  div.owl-nav {
    &.disabled {
      display: block;
    }
    button {
      padding: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      &:hover {
        span {
          &:before {
            color: #232323;
          }
        }
      }
      span {
        font-size: 0;
        display: block;
        width: 60px;
        height: 60px;
        line-height: 56px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        &:before {
          font-family: 'icomoon';
          color: #ccc;
          font-size: 15px;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }
      }

      &.owl-prev {
        left: 0;

        span:before {
          content: "\e905";
        }
      }
      &.owl-next {
        right: 0;

        span:before {
          content: "\e906";
        }
      }
    }
  }
  .owl-item {
    img {
      width: auto !important;
    }
  }
}