.form.wishlist.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        width: 100%;
        color: $color-red5;
        border: 2px solid $color-red5;
        margin-bottom: 35px;

        &:hover {
            background-color: $color-red5;
            color: $color_white;
        }
    }
}

.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol( $icon-wishlist-empty );
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered( $_table_type: horizontal );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin-top: 0;
            }

            .price-box {
                margin: 0;
                width: 100%;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                & > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .box-tocart {
                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }
            }
        }
    }
}
.wishlist-index-index {
    .products-grid .product-items {
        margin-left: 0;
    }
    .column.main .product-item {
        padding-left: 0;
        padding-right: 0;
    }
    .product-item {
        .product-image-container {
            width: 100% !important;
        }
        .product-item-name {
            padding-left: 29px;
            padding-right: 29px;
        }
        .product-item-inner {
            display: block !important;
            padding-left: 29px;
            padding-right: 29px;
            padding-bottom: 20px;
        }
        .product-item-tooltip {
            margin-top: 15px;
            margin-left: 29px;
            margin-right: 29px;

            .tooltip.content {
                margin-left: 0;
            }
        }
        .product-item-actions {
            display: block;
            margin-top: 12px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                .product-item-name, .price-box, .product-item-inner {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .product-item-tooltip {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset {
                    .field.qty {
                        margin-bottom: $indent__s;
                        padding-right: $indent__s;

                        .label {
                            width: auto;
                        }
                    }
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                }
            }
        }
        .actions-toolbar > .primary .action {
            margin-bottom: -2px;
        }
    }
}

@media only screen and (max-width: $screen__l - 1) and (min-width: $screen__m) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                width: 31%;
                margin-left: 3.5%;

                &:nth-child(2n+1) {
                    margin-left: 3.5%;
                }
                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 5 * 19%) / 4);
                padding: 0;
                width: 19%;

                &:nth-child(2n + 1) {
                    margin-left: calc((100% - 5 * 19%) / 4);
                }
                &:nth-child(5n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
