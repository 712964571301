//
//  Common
//  _____________________________________________

body {
    background-color: $page__background-color;
}

//
//  FAQ
//  _____________________________________________
.phpcuong_faqspro-index-index {
	.row-eq-height {
		li {
			.block  {
				.img-box:hover {
					border: none !important;
				}
				&:hover {
					 .img-box {
						 box-shadow: none !important;
					 }
				}
			}
			.block:hover  {
				.img-box {
					box-shadow: none !important;
				}
			}
			a {
				color: #1979c3 !important;
			}
		}
	}
}

ol.items.faqs-list  {
	.item {
		span {
			color: #1979c3 !important;
		}
		span.quesion {
			padding-right: 5px;
		}
		.detail {
			display: flex;
			align-items: flex-start;
			.answer {
				position: relative;
				top: 7px;
				padding-right: 5px;
			}
		}
	}
}


.faqspro-search-form {
	margin-bottom: 20px;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $color-gray0;
    color: $color-white;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        display: table;
        width: 100%;
    }
}

.logo {
    max-width: 50%;
    width: 300px;
    position: relative;
    z-index: 5;
    display: table-cell;
    vertical-align: top;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    & > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    background: #232323 url(../images/footer-bg.jpg) 0 100% no-repeat;
    margin-top: auto;
    color: $color-gray52;
}

.footer {
    &.content {
        .links {
            & > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding           : 0,
                $_dropdown-list-item-padding         : 0,
                $_dropdown-toggle-icon-content       : $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide                : true,
                $_icon-font-size                     : 22px,
                $_icon-font-line-height              : 22px,
                $_dropdown-list-min-width            : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    background-color: $color-gray-light0;
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        & > .breadcrumbs,
        & > .top-container,
        & > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            & > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                & > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            padding-left: $indent__xs;
                        }
                    }
                }
            }
        }
    }

    .page-header {
        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        & > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        img {
            max-height: inherit;
            display: block;
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @include lib-clearfix();
        }
    }
}
.amshopby-extension-link {
    display: none;
}
