.footer-images {
  text-align: center;

  img {
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.page-footer {
  * {
    box-sizing: border-box;
  }
  .footer-top {
    padding-top: 90px;
    padding-bottom: 170px;
    min-height: 500px;
  }
  .footer-bottom {
    padding-top: 15px;
    padding-bottom: 5px;
    background-color: #fff;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.social-links {
      margin-bottom: 120px;
    }
    &.footer-links {
      margin-top: 17px;
    }
  }
}
.footer-col-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 10px;
}
.footer-col-content {
  p {
    line-height: 24px;
    margin: 0;
  }
}
.footer-top-col {
  float: left;
  width: 33.33%;
}
.footer-column-left {
  width: 25%;
  padding-right: 2%;
  .footer-col-content {
    max-width: 320px;

    > a {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      display: inline-block;
      height: 60px;
      line-height: 56px;
      border: 2px solid #545454;
      text-decoration: none;
      padding: 0 25px;
      margin-top: 35px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #232323;
      }
    }
  }
}
.footer-column-center {
  width: 50%;
  text-align: center;
  padding: 0 2%;
}
.footer-column-right {
  float: right;
  width: 25%;
  padding-left: 2%;
}
.social-links {
  li {
    display: inline-block;
    margin: 0 15px 0 0;

    &:last-child {
      margin-right: 0;
    }
    a {
      display: inline-block;
      width: 60px;
      height: 60px;
      line-height: 56px;
      border: 2px solid #313131;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-decoration: none;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #232323;
      }
    }
  }
}
.footer-links {
 li {
   display: inline-block;
   margin: 0 5px 0px 0;

   &:after {
     content: '';
     display: inline-block;
     vertical-align: middle;
     width: 2px;
     height: 16px;
     background-color: #4f4f4f;
     margin: -2px 0 0 9px;
   }
   &:last-child {
     margin-right: 0;
     &:after {
       display: none;
     }
   }

   a {
     color: #fff;
     font-size: 16px;
     font-weight: 600;
     text-transform: uppercase;
     vertical-align: middle;
     -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
     -ms-transition: all 0.3s;
     -o-transition: all 0.3s;
     transition: all 0.3s;

     &:hover {
       color: #fff;
     }
   }
 }
}
#newsletter {
  &::-webkit-input-placeholder {color: #666 !important;}
  &::-moz-placeholder {color: #666 !important;}
  &:-moz-placeholder {color: #666 !important;}
  &:-ms-input-placeholder {color: #666 !important;}
}

@media only screen and (max-width: 1120px) {
  .page-footer {
    background-image: none;

    .footer-top {
      min-height: 0;
      padding-bottom: 0;
      padding-top: 65px;
    }
    .footer-bottom {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    ul {
      &.footer-links {
        margin-top: 0;
        text-align: left;
        float: left;
        width: 24%;
        padding-right: 2%;

        li {
          display: block;
          margin: 0 0 22px;

          &:after {
            display: none;
          }
        }
      }
      &.social-links {
        margin-bottom: 0;
        float: left;
        width: 50%;
        text-align: left;
        margin-right: 2%;
      }
    }
  }
  .footer-column-left, .footer-column-right {
    width: 48%;
    padding: 0;

    .footer-col-content {
      margin-bottom: 74px;
    }
  }
  .footer-column-center {
    width: 100%;
    width: calc(100% + 30px);
    margin-left: -15px;
    background-color: #2b2b2b;
    float: none;
    clear: both;
    padding: 70px 15px 45px;
  }
  .block.newsletter {
    input {
      height: 50px;
    }
    .actions button {
      height: 50px;
      line-height: 50px;
    }
  }
  .footer-col-title {
    font-size: 26px;
    margin-bottom: 22px;
  }
  .footer-col-content p {
    font-size: 18px;
  }
  .footer-column-left .footer-col-content {
    max-width: 450px;

    > a {
      width: 100%;
      height: 50px;
      line-height: 46px;
      text-align: center;
    }
  }
  .social-links li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 940px) {
  .footer-column-left, .footer-column-right {
    float: none;
    width: 100%;
  }
  .footer-column-left .footer-col-content {
    max-width: 100%;
  }
  .page-footer {
    ul {
      &.social-links {
        margin-right: 0;
        width: 300px;
      }
      &.footer-links {
        width: calc((100% - 300px)/2);
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .footer-top {
    .page-footer & {
      padding-top: 0;
    }
    .content {
      display: flex;
      flex-direction: column;

      .footer-column-left {
        order: 1;
      }
      .footer-column-center {
        order: 2;
      }
      .footer-column-right {
        order: 3;
      }
    }
  }
  .footer-col-title {
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 12px;
  }
  .footer-col-content p {
    font-size: 14px;
  }
  .footer-column-left .footer-col-content > a {
    margin-top: 28px;
  }
  .block.newsletter {
    margin-top: 28px;
  }
  .footer-column-left, .footer-column-right {
    .footer-col-content {
      margin-bottom: 30px;
    }
  }
  .footer-column-center {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .page-footer ul {
    &.social-links {
      float: none;
      width: 100%;
      text-align: center;
      margin-bottom: 44px;
    }
    &.footer-links {
      width: 50%;

      &:last-child {
        padding-right: 0;
        padding-left: 2%;
      }
      li {
        margin-bottom: 14px;
      }
      a {
        font-size: 12px;
      }
    }
  }
}
