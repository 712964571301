.form.send.friend {
    @extend .abs-add-fields;
}

.action.mailto.friend {
    &:before {
        content: '\e90e';
        font-family: 'icomoon';
        font-size: 18px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form.send.friend {
        @extend .abs-add-fields-desktop;
    }
}
