a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-button-primary(
    $_button-color                      : $color-white,
    $_button-border                     : 2px solid $color-red5,
    $_button-background                 : $color-red5,

    $_button-color-hover                : $color-red5,
    $_button-border-hover               : 2px solid $color-red5,
    $_button-background-hover           : $color-white,

    $_button-color-active               : $color-red5,
    $_button-background-active          : $color-white,
    $_button-border-active              : 2px solid $color-red5
    );
}
