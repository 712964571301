//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border        : $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding       : $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding: 15px !default;

$checkout-payment-method-content__padding__xl : 22px !default;

$checkout-billing-address-details__line-height: 27px !default;
$checkout-billing-address-details__padding    : 0 0 0 23px !default;
$checkout-billing-address-form__max-width     : $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________

.checkout-payment-method {
    .step-title {
        margin-bottom: 0;
    }

    .payment-method {
        &:first-child {
            .payment-method-title {
                border-top: 0;
            }
        }

        &._active {
            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-content {
        & > .actions-toolbar {
            & > .primary {
                .action {
                    &.primary {
                        @extend .abs-button-l;
                    }
                }
            }
        }
    }

    .payment-method-title {
        border-top: $checkout-payment-method-title__border;
        padding: $checkout-payment-method-title__padding 0;
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }
    }

    .payment-method-content {
        display: none;

        padding: 0 0 $indent__base $checkout-payment-method-content__padding__xl;
        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        max-width: $checkout-billing-address-form__max-width;
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .payment-method-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .action-cancel {
            @extend .abs-action-button-as-link;
        }

        .billing-address-details {
            line-height: $checkout-billing-address-details__line-height;
            padding: $checkout-billing-address-details__padding;

            .action-edit-address {
                @extend .abs-action-button-as-link;
            }
        }
    }

    .payment-method-note {
        & + .payment-method-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        & > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-methods {
            margin: 0 (-$checkout-payment-method-title-mobile__padding);
        }

        .payment-method-title {
            padding: $checkout-payment-method-title-mobile__padding;
        }

        .payment-method-content {
            padding: 0 $checkout-payment-method-title-mobile__padding $indent__base;
        }

        .payment-method-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-methods {
            .actions-toolbar {
                .primary {
                    float: right;
                    margin: 0;
                }
            }
        }

        .fieldset {
            & > .field-select-billing {
                & > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                & > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .payment-method-billing-address {
        .action-update {
            float: right;
        }

        .actions-toolbar {
            .action-cancel {
                margin: 6px $indent__base 0 0;
            }
        }
    }
}
