.desktop-menu-wrapper {
  float: left;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
.desktop-menu {
  .nav-level-0 {
    display: inline-block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &.active, &:hover {
      .nav-link-level-0 {
        border-color: #f33e26;
      }
    }
    &:hover {
      .submenu-wrapper {
        display: block;
      }
    }
  }
  .submenu-level-0 {
    margin: 0 auto;
    padding: 45px 15px 48px;
    font-size: 0;
    text-align: center;
  }
  a {
    display: inline-block;
  }
  .nav-level-0 {
    margin: 0;
  }
  .nav-link-level-0 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 1.1rem;
    height: 60px;
    line-height: 60px;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    text-decoration: none;
  }

  .submenu-wrapper {
    display: none;
    position: absolute;
    z-index: 6;
    background: #fff url(../images/nav-bg.png) 50% 0 no-repeat;
    background-size: 100% 220px;
    width: calc(100vw - 17px);
    left: 0;
  }
  .nav-level-1 {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0 10px;

    .category-image-wrapper {
      display: table;
      width: 100%;
      margin-bottom: 35px;
    }
    .category-image {
      display: table-cell;
      vertical-align: middle;
      height: 120px;

      img {
        max-width: 150px;
        max-height: 120px;
        display: block;
        margin: 0 auto;
      }
    }
    .category-name {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      border: 2px solid rgba(21, 21, 21, 0.1);
      padding: 0 15px;
      color: #151515;
      font-size: 16px;
      font-weight: 600;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        background-color: #f33e26;
        color: #fff;
        
        i {
          color: #fff;
        }
      }

      > * {
        vertical-align: middle;
        display: inline-block;
      }
      i {
        color: #e7e7e7;
        font-size: 7px;
        margin-left: 16px;
      }
    }
  }
  .submenu-level-1 {
    margin-top: 34px;
  }
  .nav-level-2 {
    margin: 0;
  }
  .nav-link-level-2 {
    line-height: 32px;
    color: #5b5b5b;

    .category-counter {
      font-size: 12px;
    }
  }
}
.mobile-menu-wrapper {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #232323;
  width: 100%;
  text-align: center;
  z-index: 10;

  .header-left-links {
    display: none;
    padding: 15px 15px 20px;
    margin: 0;
    border: none;

    li {
      display: block;
      margin: 0;

      a {
        display: block;
        line-height: 30px;
      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;

    &.mobile-menu {
      font-size: 20px;

      ul {
        font-size: 0.85em;
        padding: 0;
      }
    }

    li {
      a {
        display: block;
      }
    }
  }
  .submenu {
    display: none;
  }
}
.mobile-menu {
  margin: 0;
  padding: 18px 15px;
  text-align: center;
  font-weight: 600;
  background: #151515 url(../images/mobile-menu-bg.jpg) 50% 0 no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  li {
    display: block;
    margin: 0;

    a {
      display: block;
      color: #fff;
      line-height: 1.95;

      &.has-child {
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 4px 0 4px;
          border-color: #fff transparent transparent transparent;
          margin-left: 5px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          position: absolute;
        }
      }
    }
  }
}

@media only screen and (max-width: 1240px) {
  .desktop-menu .nav-link-level-0 {
    padding: 0 0.7rem;
  }
}

@media only screen and (max-width: 1120px) {
  .desktop-menu .nav-link-level-0 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .desktop-menu-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 860px) {
  .mobile-menu-wrapper {
    .header-left-links {
      display: block;
    }
  }
}

.desktop-menu .bran-menu .submenu-level-1 {
  display: none;
}
body .desktop-menu .bran-menu .nav-level-1 {
    text-align: left;
}
.desktop-menu .bran-menu .nav-level-1 a.nav-link-level-1 span {
    border: 0;
    background: transparent;
}
body .desktop-menu .bran-menu .submenu-level-1 {
    margin-top: 34px;
    display: none;
    position: absolute;
    background: #fff;
    z-index: 9;
    left: 11px;
    top: 0px;
    box-shadow: 0 0 2px 2px #ccc;
}
body .desktop-menu .bran-menu .nav-level-1:hover ul.submenu-level-1 {
    display: block;
}
body .desktop-menu .bran-menu .nav-level-1 {
  position: relative;
}
body .desktop-menu .bran-menu .submenu-level-1 > li {
    padding: 2px 14px;
}
body .desktop-menu .bran-menu .submenu-level-0 {
  text-align: left;
}
.desktop-menu .bran-menu .nav-level-1:hover a.nav-link-level-1 > .category-name {
    background-color: #f33e26;
        color: #fff;
}
.brand-filter {
    font-size: 16px;
    margin-bottom: 26px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    span {
        color: #151515;
        border: 1px solid #151515;
        padding: 9px 12px;
        margin: 6px 6px;
        line-height: 16px;
        cursor: pointer;
    }
    span.active,
    span:hover {
	    background: #f33e26;
	    color: #fff;
	    border-color: #f33e26;
	}
}
.desktop-menu .submenu-wrapper {
    background-size: cover;
}


