$main__color: #33ebc7;
$sub__color: #000;
$font__color: #808080;
$white__color: #fff;
$info_icon__color: #ecedef;
$border__color: #c9c9c9;

$dark_theme_main__color: $sub__color;
$dark_theme__color: $main__color;
$light_theme_main__color: $main__color;
$light_theme__color: $sub__color;
$screen__m: 768px;

@font-face {
    font-family: 'LexendDeca';
    src: url('../fonts/LexendDeca.woff2') format('woff2'),
    url('../fonts/LexendDeca.woff') format('woff'),
    url('../fonts/LexendDeca.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.in3 {
    position: relative;
    display: inline-flex;
    align-items: center;

    div {
        &.logo {
            display: block;
            height: 37px;
            margin-bottom: initial;
            padding: 5px 5px 5px 0;
            max-width: initial;
            width: 85px;

            svg {
                height: 37px;
                width: 85px;
            }
        }
    }
}

.in3-tooltip {
    background-color: $white__color;
    border: none;
    color: $font__color;
    display: block;
    font-size: 13px;
    font-style: italic;
    height: 0;
    line-height: 1.3;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    text-decoration: none;
    transition: opacity .5s ease-out;

    &.show {
        opacity: 100%;
        overflow: auto;
        height: auto;
        margin: 5px 0 7px;
        border: 1px solid $border__color;
        padding: 10px;
        border-radius: 7px
    }

    a {
        color: $font__color;
        text-decoration: none;
    }
}

.in3-widget {
    box-sizing: border-box;
    color: $dark_theme_main__color;
    display: inline-flex;
    font-family: inherit;
    overflow: hidden;
    padding: 0 26px 5px 2px;
    text-align: left;

    i {
        &.info {
            align-items: center;
            background-color: $info_icon__color;
            border-radius: 50%;
            content: '';
            display: flex;
            height: 13px;
            justify-content: center;
            margin-top: -9px;
            position: absolute;
            right: 10px;
            top: 50%;
            width: 13px;

            &:hover {
                cursor: pointer;
            }

            svg {
                height: 8px;
                width: 8px;
            }
        }
    }

    p {
        font-family: 'LexendDeca', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.3;
        margin: 0;
        padding: 3px 0 0px;
        color: #fff
    }

    strong {
        font-weight: 700;
    }
}

.in3-widget-container {
    z-index: 1;
}

.checkout-cart-index {
    #in3 {
        flex-wrap: wrap;
    }

    .cart-summary {
        .in3 {
            margin: 0;
            width: 100%;

            .in3-widget {
                margin: 3px 0;
                min-height: 30px;
                width: calc('100% - 10px');

                p {
                    font-size: 12px;
                }
            }

            .in3-tooltip {
                margin-top: 10px;
            }
        }
    }
}

.checkout-index-index {
    .in3 {
        margin: 0 10px;

        &-widget {
            width: 100%;
        }

        &-tooltip {
            margin: 0 10px 30px;
        }
    }
}

@media(max-width:767px) {
    .in3 {
        div {
            &.logo {
                margin-left: 0;
            }
        }

        .in3-widget {
            margin-top: 10px;

            i {
                &.info {
                    margin-top: -4px;
                }
            }
        }
    }

    .checkout-cart-index {
        div {
            &.logo {
                margin-top: 0;
            }
        }

        .cart-summary {
            .in3 {
                margin-left: 5px;
                width: 98%;

                .in3-widget {
                    width: 90%;
                    margin-top: 10px;
                }
            }
        }
    }
}

body.checkout-cart-index{
    .cart-summary  {
        .in3  {
            .in3-widget {
                margin: 3px 0;
                min-height: auto !important;
                padding-right: 0 !important;
                .in3-widget-container {
                    display: flex;
                    align-items: center;
                    i {
                        margin-left: 5px;
                        background: #ccc;
                        margin-top: 0;
                        position: static;
                    }
                }
                p {
                    color: #000;
                }
            }
        }
    }
}

body.checkout-index-index {
    .in3  {
        .in3-widget {
            margin: 3px 0;
            min-height: auto !important;
            padding-right: 0 !important;
            .in3-widget-container {
                display: flex;
                align-items: center;
                i {
                    margin-left: 5px;
                    background: #ccc;
                    margin-top: 0;
                    position: static;
                }
            }
            p {
                color: #000;
            }
        }
    }
}