﻿.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        margin-bottom: $indent__base;

        strong {
            font-size: 18px;
        }
    }
}

.block-subtitle {
    display: none
}

.filter-current {
    padding: 32px 10% 5px;
    .item {
        margin-bottom: $indent__s;


        .action.remove {
            font-size: 0;

            span {
                &:before {
                    content: '+';
                    font-size: 24px;
                    transform: rotate(45deg);
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -10px;
                    margin-left: 5px;
                    font-weight: bold;
                    color: #f33e26;
                }
            }
        }
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        padding: 0 10% $indent__l;
        border-bottom: 1px solid $color-gray90;

        .action {

            &.filter-clear {
                text-decoration: none;
                display: inline-block;
                height: 36px;
                line-height: 36px;
                background-color: #fff;
                border: 2px solid rgba(21, 21, 21, 0.1);
                padding: 0 15px;
                color: #151515;
                font-size: 16px;
                font-weight: 600;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;

                &:hover {
                    background-color: #f33e26;
                    color: #fff;
                }
            }
        }
    }
    .filter-current-subtitle {
        font-size: 16px;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 25px;
        word-break: break-all;
        position: relative;
        padding-right: 20px;
        cursor: pointer;

        &:before, &:after {
            content: '';
            background-color: $color-gray0;
            position: absolute;
            width: 14px;
            height: 2px;
            right: 2px;
            top: 8px;
        }
        &:after {
            transform: rotate(90deg);
        }
    }

    .filter-options-content {
        display: none;
        margin: 0 0 28px;

        .item {
            margin-bottom: 15px;
            margin-left: 20px;
            position: relative;

            a {
                color: inherit;
            }
        }

        .swatch-option.text {
            display: inline-block;
            font-weight: 600;
            min-width: 45px;
            height: 45px;
            line-height: 41px;
            border: 2px solid $color-gray-darken7;
            padding: 0 2px;
            text-align: center;
            margin-right: 11px;
            margin-bottom: 17px;
            margin-left: 0;
            box-sizing: border-box;

            &.active {
                border-color: $color-gray0;
                background-color: $color-gray0;
                color: $color_white;
            }
        }
    }

    .count {

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
    .ui-slider-horizontal {
        height: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin: 0 5px;
    }
    .ui-slider-handle {
        background-color: $color-gray0;
        margin-top: -3px;
    }
    .ui-slider-range {
        position: absolute;
        height: 3px;
        background-color: $color-gray0;
    }
    .clear-button {
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        display: inline-block;
        height: 60px;
        line-height: 56px;
        border: 2px solid $color-red5;
        background-color: $color-red5;
        text-decoration: none;
        padding: 0 25px;
        margin-top: 10%;
        margin-left: 10%;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        
        &:hover {
            background: none;
            color: $color-red5;
            border: 2px solid $color-red5;
        }
    }
}
.filter-options-item {
    border-bottom: 1px solid $color-gray90;
    padding: 32px 10% 5px;

    &.active {
        .filter-options-title:after {
            display: none;
        }
        .filter-options-content {
            display: block;
        }
    }
    label {
        cursor: pointer;
        box-sizing: border-box;
    }
    input[type="checkbox"]:not([name="maat"]) {

        & + label {
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 18px;
                height: 18px;
                border: 2px solid $color-gray-darken7;
                position: absolute;
                right: 0;
                box-sizing: border-box;
            }
        }
        &:checked + label {
            &:before {
                background-color: $color-gray0;
                border-color: $color-gray0;
            }
            &:after {
                content: '\e90d';
                font-family: 'icomoon';
                color: $color_white;
                position: absolute;
                right: 3px;
                font-size: 8px;
                margin-top: 3px;
            }
        }
    }
    &:last-child {
        border-bottom: none;
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        position: relative;
        padding-left: 22px;
        margin-bottom: 6px;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            left: -6px;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
            }
        }
    }
}
