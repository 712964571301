.catalog-product-view {
  .page-main {
    max-width: 1330px;
    padding-top: 38px;
  }
  .page-title {
    margin: -7px 0 0;
    font-size: 34px;
    font-weight: 800;
    line-height: 39px;
    text-align: center;
  }
  .product.data.items {
    color: #4d4d4d;
    line-height: 24px;

    > .item.title {
      cursor: pointer;
      margin: 0 0 0 45px;
      padding: 0;

      &.active {
        > .switch {
          color: #232323;
          border-color: #f33e26;
          padding: 2px 0;
        }

        + .item.content {
          display: block;
        }
      }
      > .switch {
        background: none;
        border: none;
        color: #919191;
        font-weight: 600;
        border-bottom: 2px solid transparent;
        padding: 2px 0;

        &:hover {
          background: none;
          padding: 2px 0;
          color: #232323;
        }
      }
    }
    > .item.content {
      background-color: #f6f6f6;
      border: none;
      padding: 5%;
      margin-top: 57px;
      display: none;
    }

    h2 {
      font-size: 19px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 22px;
      color: #232323;
    }
    ul {
      padding: 0;
      max-width: 480px;
      list-style-type: none;
      font-size: 0;
      margin: 32px 0 0;

      li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        font-size: 14px;
        padding: 0 22px;
        position: relative;
        box-sizing: border-box;
        margin: 0 0 5px;

        &:before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #232323;
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
    }
  }
  .columns {
    display: block;
  }
  .column.main {
    padding-bottom: 105px;

    .product-info-main ~ * {
      width: calc(100% - 400px);
    }
    .product-items {
      margin-left: 0;
    }

    .block-title {
      text-align: center;
      color: #232323;
      font-size: 25px;
      font-weight: 700;

      strong {
        font-size: inherit;
      }
    }
  }
  .block.related {
    margin-bottom: 80px;

    .products-grid {
      .product-item {
        width: 30%;
        margin-right: 5%;
        padding: 33px 29px 0;

        &:nth-child(3n) {
          margin-right: 0;
          margin-bottom: 35px;
        }
      }
      .owl-carousel {
        .product-item {
          width: 100%;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .fotorama__stage {
    max-height: 615px;
  }
  .page-title-wrapper .page-title {
    font-family: "Montserrat", sans-serif;
  }
}
.product-info-main {
  background: #232323 url(../images/product-main-info-bg.jpg) 50% 50% no-repeat;
  background-size: 100% 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 0 45px;
  position: relative;
  overflow: hidden;

  .action {
    display: inline-block;
    height: 60px;
    line-height: 56px;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    padding: 0 20px;
  }
  .product-social-links {
    padding: 21px 45px;
    background-color: #f6f6f6;
    margin-left: -45px;
    width: calc(100% + 90px);
    box-sizing: border-box;
  }
  .product-info-price {
    padding-top: 35px;
  }
  .swatch-option {
    display: inline-block;
    min-width: 45px;
    height: 45px;
    line-height: 41px;
    text-align: center;
    background-color: #3c3c3c;
    border: 2px solid #525252;
    color: #fff;
    margin: 0 15px 17px 0;
    font-weight: 600;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 2px;

    &.selected {
      background-color: #fff;
      border-color: #fff;
      color: #232323;
    }
  }
  .swatch-select {
    color: #232323;
  }
  .swatch-attribute.maat {
    margin-bottom: -17px;
  }
  .product-label-container {
    background-color: rgba(255, 255, 255, 0.2);
  }
  select {
    color: #232323;
    background: #fff;
  }
  .rewardpoints-product-view-earning {
    margin-bottom: 25px;
  }
}
.product-breadcrumbs {
  color: #f33e26;
  font-size: 20px;
  margin-top: -6px;
  margin-bottom: 38px;

  a {
    color: inherit;
    text-decoration: none;

    i {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
      margin-right: 17px;
    }
  }
}
.product-media-container {
  position: relative;
  padding-left: 130px;
  margin-bottom: 35px;

  .fotorama {
    overflow: visible;
    position: static !important;

    .fotorama__thumb-border {
      border-width: 4px;
      display: none;
    }
    .fotorama__nav__frame--thumb {
      box-sizing: border-box;
      border: 4px solid #f6f6f6;

      .fotorama__thumb {
        opacity: 0.4;
      }

      &.fotorama__active {
        border-color: #232323;

        .fotorama__thumb {
          opacity: 1;
        }
      }
    }
  }
  .fotorama__stage__shaft {
    position: static;
  }
  .fotorama__nav-wrap--vertical .fotorama__nav__frame--thumb {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .fotorama__nav__shaft {
    white-space: normal;
  }
  .fotorama__stage {
    left: 0 !important;
  }
  .fotorama__nav-wrap {
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .fotorama__wrap {
    position: static;
  }
  .fotorama__stage__shaft {
    max-width: 100% !important;
  }
  .fotorama__arr--next {
    right: 0 !important;
  }
}
.product-brand-wrapper {
  display: table;
  border: 4px solid #f6f6f6;
  background-color: #fff;
  width: calc(100% + 90px);
  margin-left: -45px;
  box-sizing: border-box;
  color: #232323;
  font-size: 28px;
  font-weight: bold;


  .product-brand {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 79px;
    padding: 15px;
    box-sizing: border-box;
  }
  .product-brand-logo {
    display: block;
    margin: 0 auto;
    max-height: 85px;
  }
}
.delivery-info-wrapper {
  font-size: 13px;
  line-height: 22px;
  font-style: italic;
  color: #939393;
  text-align: center;
  margin: 0 -5px 30px;
  position: relative;
  padding-top: 24px;

  &:before, &:after {
    content: '';
    display: block;
    height: 2px;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -45px;
  }
  &:after {
    left: auto;
    right: -45px;
  }

  .delivery-info {
    &:before {
      content: '\e903';
      font-family: 'icomoon';
      color: #fff;
      font-size: 22px;
      position: absolute;
      top: -11px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  strong {
    color: #fff;
  }
}
.product-social-links {
  a {
    display: inline-block;
    border: 2px solid #e0e0e0;
    color: #232323;
    float: left;
    width: 48%;
    margin-right: 4%;

    &:hover {
      background-color: #e0e0e0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.product-info-price {
  > .price-box {
    display: table;
    width: 100%;

    .old-price {
      display: table-footer-group;
      color: #808080;
      text-align: center;
      padding-top: 22px;

      .price-wrapper {
        vertical-align: middle;

        .price {
          font-size: 19px;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
      .price-container {
        margin-right: 16px;
      }
      > span {
        display: inline-block;
        margin-top: 22px;
      }
    }
    .special-price {
      display: table-header-group;
    }
    .old-price, .special-price {
      .old-price {
        display: none;
      }
    }
  }
}
.main-info-text {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 32px;
}
.upsell-products-wrapper {
  padding-left: 130px;
}
.upsell-product-items {
  text-align: center;
  padding: 0;

  * {
    box-sizing: border-box;
  }

  .upsell-item {
    display: inline-block;
    margin: 0 10px 30px;
    max-width: 100px;
    vertical-align: top;
  }
  .upsell-photo-wrapper {
    display: table;
    margin-bottom: 18px;

    .upsell-photo {
      display: table-cell;
      text-align: center;
      vertical-align: middle;

      .product-item-photo {
        display: inline-block;
        border: 4px solid #f6f6f6;
        height: 100px;
        max-width: 100px;
        padding: 9px;

        span {
          display: inline-block;
          height: 100%;
        }
      }
      .product-image-photo {
        margin: 0 auto;
        display: block;
        position: static;
        max-height: 100%;
      }
      .product-image-wrapper {
        padding: 0 !important;
      }
    }
  }
  .product-item-name {
    margin: 0;

    a.product-item-link {
      font-weight: 400;
      line-height: 18px;
    }
  }
  &.owl-carousel {
    max-width: 460px;
    margin: 0 auto;

    .upsell-item {
      margin: 0;
    }
    .owl-nav {
      &.disabled {
        display: none;
      }
      button {
        &.owl-prev {
          left: -60px;
        }
        &.owl-next {
          right: -60px;
        }
      }
    }
  }
}
.attribute-scale-wrapper {
  margin-top: 40px;
}
.attribute-scales {
  * {
    box-sizing: border-box;
  }
  .attribute-scale {
    float: left;
    width: 50%;
    padding-right: 8%;
  }
  .scale-title {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    color: #232323;
  }
  .scale-mark-item-container {
    float: left;
    width: 33.33%;
    position: relative;
    padding-top: 8px;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 5px;
      width: 1px;
      background-color: #454545;
      top: 0;
    }
    .scale-mark-item {
      text-transform: uppercase;
      line-height: 1;
      font-size: 12px;
    }
    &:nth-child(1) {
      &:before {
        left: 0;
      }
    }
    &:nth-child(2) {
      text-align: center;

      &:before {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &:nth-child(3) {
      text-align: right;
      float: right;

      &:before {
        right: 0;
      }
    }
  }
}
.scale-range-container {
  position: relative;
  height: 8px;
  border: 1px solid #454545;
  background-color: #fff;
  margin-top: 25px;

  .scale-range {
    position: absolute;
    top: 0;
    background-color: #454545;
    height: 6px;
  }
}
div.rating-summary {
  .rating-result {
    &:before {
      color: #d3d3d3;
    }
    > span {
      &:before {
        color: #232323;
      }
    }
  }
}
div.review-control-vote {
  color: #d3d3d3;

  label {
    &:before {
      color: #232323;
    }
  }
}
.video-wrapper {
  margin-top: 25px;

  .video-wrapper-container {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  img {
    display: block;
  }
  &:hover {
    .play-button {
      color: #f33e26;
      background: none;
    }
  }
  .play-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    line-height: 56px;
    background-color: #f33e26;
    border: 2px solid #f33e26;
    color: #fff;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:before {
      content: '';
      width: 35px;
      height: 35px;
      display: inline-block;
      border-radius: 50%;
      background-color: #a42918;
      border: 5px solid #c93925;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4.5px 0 4.5px 6px;
      border-color: transparent transparent transparent #ffffff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2px;
      right: 0;
      margin: auto;
    }
  }
}
.product-video-item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1120px) {
  .catalog-product-view {
    .column.main .product-info-main ~ * {
      width: 100%;
    }
  }
  .product-info-main {
    top: 0 !important;
  }
}
@media only screen and (max-width: 860px) {
  .catalog-product-view .header-content-right .header-top-right-text {
    background-color: rgba(21, 21, 21, 0.1);
    color: rgba(21,21,21,0.8);
  }
}
@media only screen and (max-width: 880px) {
  .page-layout-1column {
    .product.media {
      float: none;
      width: 100%;
    }
    .product-info-main, .product.info.detailed {
      float: none;
      margin-left: -15px;
      width: calc(100% + 30px);
    }
  }
  .product-brand-wrapper {
    border-left: none;
    border-right: none;
  }
  .product-media-container {
    .page-title {
      margin-bottom: 22px;
    }
    .product-info-price {
      position: relative;

      .product-label-container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .price-box {
        display: block;
        text-align: center;

        > span {
          display: inline-block;
          margin: 0;
          padding: 0;

          &.old-price {
            margin-right: 10px;
            padding-top: 34px;

            .price-container {
              margin: 0;
            }
            .price {
              font-size: 16px;
              font-weight: 400;
              text-decoration: line-through;
              color: rgba(21,21,21,0.35);
            }
          }
          &.price-with-discount {
            padding-top: 30px;
          }
        }
        .price {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    .gallery-placeholder {
      margin-top: 25px;
    }
  }
  .product-brand-wrapper {
    margin-bottom: 35px;
    margin-left: -15px;
    width: calc(100% + 30px);

    .product-brand {
      height: 70px;
    }
  }
  .main-info-text {
    display: none;
  }
  .product-info-main {
    padding: 0 15px;
    margin-bottom: 25px;

    .action {
      height: 44px;
      line-height: 40px;
    }
    .product-social-links {
      padding: 20px 15px;
      margin-left: -15px;
      width: calc(100% + 30px);
    }
  }
  .product-info-main .box-tocart .action.tocart, .product-options-bottom .box-tocart .action.tocart {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .attribute-scales .attribute-scale {
    float: none;
    width: 100%;
    padding-right: 0;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media only screen and (max-width: 767px) {
  .catalog-product-view {
    .column.main .block-title {
      font-size: 20px;
      line-height: 1.1;
      text-transform: uppercase;
    }
    .page-title {
      font-size: 18px;
      line-height: 24px;
      padding: 0 30px;
      margin-top: 0;
    }
    .page-main {
      padding-top: 48px;
    }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows {
      .fotorama__arr--prev, .fotorama__arr--next {
        &:not(:focus) {
          transform: none;
          opacity: 1;
        }
      }
    }
    .fotorama__stage {
      overflow: visible;
      z-index: 9;
    }
    .fotorama__arr {
      color: #232323;
      font-size: 12px;
      font-family: 'icomoon';
      width: auto;
      height: auto;
      top: auto;
      bottom: -54px;

      .fotorama__arr__arr {
        display: none;
      }
      &.fotorama__arr--next {
        &:before {
          content: '\e906';
        }
      }
      &.fotorama__arr--prev {
        &:before {
          content: '\e905';
        }
      }
    }
    .fotorama__nav-wrap {
      position: static;
      width: 100%;
      margin-top: 28px;
      box-sizing: border-box;
      padding: 0 20px;
    }
    .product.data.items {
      ul {
        li {
          width: 100%;
          padding-right: 0;
        }
      }
      > .item {
        &.title {
          margin: 0;

          &.active {
            > .switch {
              border-bottom: 2px solid #f33e26;
              padding: 12px 30px 12px 15px;

              &:after {
                display: none;
              }
            }
          }
          > .switch {
            border-bottom: 1px solid #e9e9e9;
            padding: 12px 30px 12px 15px;
            position: relative;
            font-size: 14px;

            &:before, &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 15px;
              width: 14px;
              height: 2px;
              background-color: #232323;
              margin: auto;
            }
            &:after {
              webkit-transform: rotate(90deg);
              transform: rotate(90deg);
            }

            &:hover {
              padding: 12px 30px 12px 15px;
            }
          }
        }
        &.content {
          margin-top: 0;
          padding: 25px 15px;
        }
      }
    }
  }
  .product-media-container {
    padding-left: 0;
  }
  .upsell-products-wrapper {
    padding-left: 0;
  }
  .product-breadcrumbs {
    position: absolute;
    z-index: 1;
    margin-top: -2px;

    a {
      span {
        display: none;
      }
      i {
        font-size: 10px;
      }
    }
  }
  .product.media {
    margin-bottom: 22px;
  }
  .product-media-container {
    margin-bottom: 50px;
    overflow: hidden;
  }
  .product-info-main {
    margin-bottom: 0;
  }
  .product.info.detailed {
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 639px) {
  .review-items {
    .review-item {
      margin-bottom: 30px;
    }
  }
  .review-author-first-letter {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 23px;
  }
  .review-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .review-date {
    top: 9px;
  }
  .review-details {
    padding-left: 100px;
    min-height: 68px;

    .review-ratings {
      margin-bottom: 0;
      margin-top: -5px;
    }
  }
  .review-content:before {
    left: 41px;
  }
  a.show-review-form {
    height: 50px;
    line-height: 46px;
  }
  .review-list {
    .block-content {
      margin-top: 50px;
    }
  }
  .block.related {
    box-sizing: border-box;
    position: relative;
    padding: 0 50px;

    .catalog-product-view & {
      margin-left: -15px;
      margin-bottom: 40px;
      width: calc(100% + 30px) !important;
      overflow: hidden;


    }

    .owl-carousel {
      .owl-stage-outer {
        overflow: visible;
      }
      .owl-nav button {
        &.owl-prev {
          left: -50px;
        }
        &.owl-next {
          right: -50px;
        }
        span {
          width: 44px;
          height: 44px;
          line-height: 40px;
          background-color: #232323;
          border: 2px solid #232323;
          box-sizing: border-box;

          &:before {
            color: #fff;
            font-size: 12px;
          }
          &:hover {
            background-color: #fff;

            &:before {
              color: #232323;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 499px) {
  .upsell-product-items.owl-carousel {
    max-width: 340px;
  }
}
@media only screen and (max-width: 399px) {
  .upsell-product-items.owl-carousel {
    max-width: 220px;
  }
}
