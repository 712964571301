//
//  Rating variables
//  _____________________________________________

$rating-icon__count: 5 !default;
$rating-icon__content: $icon-star !default;
$rating-icon__font: $icon-font !default;
$rating-icon__font-size: 28px !default;
$rating-icon__letter-spacing: -10px !default;
$rating-icon__color: $color-gray78 !default;

$rating-icon__active__color: $color-orange-red2 !default;

$rating-label__hide: inherit !default;
