.checkout-index-index {
    .page-header {
        margin-bottom: 20px;
    }
    .action-auth-toggle {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        background-color: #fff;
        border: 2px solid rgba(21, 21, 21, 0.1);
        padding: 0 15px;
        color: #151515;
        font-size: 16px;
        font-weight: 600;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
            background-color: #f33e26;
            color: #fff;
            text-decoration: none;
        }
    }
    .field.street {
        margin-top: -10px;

        > legend.label {
            display: none;
        }
        .label {
            margin-top: -10px;
            font-weight: 700;
        }
        > .control {
            display: table;
            float: none;
            width: 100%;

            .field{
                float: left;

                &[name="shippingAddress.street.0"] {
                    padding-top: 24px;
                    width: calc(100% - 170px);

                    .label {
                        display: inline-block;
                        margin-bottom: 4px;
                        padding: 17px 15px 0 0;
                        text-align: right;
                        width: 33.3%;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        float: left;
                    }
                    .control {
                        width: 66.7%;
                        float: left;
                    }
                }
                &[name="shippingAddress.street.1"] {
                    width: 75px;
                    margin-left: 10px;
                    margin-top: 0;

                    .label {
                        display: inline-block;
                        margin-bottom: 4px;
                    }
                }
                &[name="shippingAddress.street.2"] {
                    width: 75px;
                    margin-left: 10px;
                    margin-top: 0;
                }

                &.additional .label {
                    display: inline-block;
                    clip: unset;
                    height: auto;
                    margin: 0;
                    width: auto;
                    position: static;
                }
                &._required > .label:after {
                    content: '*';
                    color: #e02b27;
                    font-size: 12px;
                    margin: 0 0 0 4.5px;
                }
            }
        }
    }
    .minicart-wrapper .action.showcart {
        &:before {
            position: static;
        }
        .counter.qty {
            display: none;
        }
    }
    .form-login {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
    .form-shipping-address {
        margin-top: 0;
    }
    .checkout-shipping-address {
        .fieldset > .field {
            margin-bottom: 10px;
        }
    }
}


@media only screen and (max-width: 880px) {
    .checkout-index-index .field.street > .control .field[name="shippingAddress.street.0"] {
        .control {
            width: 61.5%;
        }
        .label {
            width: 38.5%;
        }
    }
}
@media only screen and (max-width: 767px) {
    .checkout-index-index .field.street > .control .field {
        &[name="shippingAddress.street.0"] {
            width: 100%;
            padding-top: 0;
            margin-bottom: 20px;

            .control {
                width: 100%;
            }
            .label {
                width: 100%;
                text-align: left;
            }
        }
        &[name="shippingAddress.street.1"] {
            margin-left: 0;
            width: 48%;
            margin-right: 4%;
        }
        &[name="shippingAddress.street.2"] {
            margin-left: 0;
            width: 48%;
        }
    }
}

.opc-payment .items.payment-methods .payment-group .step-title + .payment-method .payment-method-title:after {
    content: ' - Met In3 betaal je in 3 termijnen met 0% rente !';
    color: #777;
    font-weight: bold;
}